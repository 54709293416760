<template>
    <div class="images">
        <div v-masonry transition-duration="0.3s" :column-width="485" :gutter="20" item-selector=".item">
            <div v-masonry-tile class="item" v-for="(item) in items" :key="item.id">
                <el-image class="el-img" :lazy="true" :src="item.thumb" :preview-src-list="[item.thumb]"
                    @load="imageLoaded">
                </el-image>
            </div>
        </div>

        <load-more :hasmore="hasmore" />
    </div>
</template>
<script>

import LoadMore from '../LoadMore.vue';
export default {
    name: 'Images',
    components: {
        LoadMore
    },
    methods: {
        imageLoaded() {
            this.$redrawVueMasonry();
        },
    },
    data() {
        return {
            hasmore: false,
            items: [

                {
                    id: 1117,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2609.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2609.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN10",
                },

                {
                    id: 1115,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2629.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2629.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN08",
                },
                {
                    id: 1114,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2639.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2639.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN07",
                },
                {
                    id: 1116,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2611.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2611.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN09",
                },
                {
                    id: 1113,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2648.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2648.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN06",
                },
                {
                    id: 1112,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2641.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2641.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN05",
                },
                {
                    id: 1111,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2595.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2595.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN04",
                },
                {
                    id: 1110,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2598.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2598.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN03",
                },
                {
                    id: 1109,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2566.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2566.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN02",
                },
                {
                    id: 1097,
                    image: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2561.jpg?imageView2/2/w/714/h/692/q/81#",
                    thumb: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2020/11/IMG_2561.jpg?imageView2/2/w/0/h/0/q/81#",
                    title: "MAN01",
                },

            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.item {
    width: 485px;
    margin-bottom: 20px;

    .el-img {
        width: 485px;
    }
}
</style>