<template>
  <div class="news-block">
    <div class="bgimg">
      <el-image class="bgimg_url" :src="thumb">
        <div slot="placeholder" class="image-slot">
          Loading<span class="dot">...</span>
        </div>
      </el-image>
    </div>
    <div class="masker">
      <div class="nav">Some News</div>
      <div class="menus">
        <carousel
          :per-page="4"
          :centerMode="true"
          :paginationEnabled="false"
          :navigationEnabled="true"
        >
          <slide v-for="(item, idx) in parseItems" :key="item.id">
            <div
              class="menu"
              :class="[item.isActive ? 'active' : '']"
              @click="toggleItem(idx)"
            >
              {{ item.title }}
            </div>
          </slide>
        </carousel>
      </div>

      <div class="description">
        {{ desc }}
      </div>

      <div class="btn">
        <router-link :to="{ path: `news/${news_id}` }">View Detail</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "News",
  components: {
    Carousel,
    Slide,
  },
  props: {
    items: {
      default: () => [],
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
  methods: {
    goIdx(idx) {
      this.$emit("goto", idx);
    },
    toggleItem(idx) {
      this.idx = idx;
    },
  },
  computed: {
    parseItems() {
      let list = this.items.map((item, idx) => {
        item["isActive"] = idx == this.idx ? true : false;
        return item;
      });
      return list;
    },
    desc() {
      let idx = this.idx;
      let item = this.items[idx];
      if (!item) return "";
      return item["desc"];
    },

    news_id() {
      let idx = this.idx;
      let item = this.items[idx];
      if (!item) return false;
      return item["id"];
    },

    thumb() {
      let idx = this.idx;
      let item = this.items[idx];
      if (!item) return "";
      return item["thumb"];
    },
  },
};
</script>

<style lang="scss" scoped>
.news-block {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  position: relative;
  border-radius: 25px;
  height: 1330px;

  .nav {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    text-align: center;

    color: #3f3f3f;
    border-bottom: 1px solid #000;
    padding: 66px 0;
    margin: 0 52px;
  }

  .bgimg_url {
    border-radius: 20px;
  }

  .btn {
    margin-left: 125px;
    margin-top: 50px;
    a {
      color: #fff;
      padding: 13px 25px;
      text-decoration: none;
      font-family: "Roboto";
      background-color: #13b0e1;
    }
  }

  .description {
    margin-top: 157px;
    margin-left: 125px;
    max-width: 600px;
    font-family: "Roboto";
    line-height: 1.6;
  }
  .menus {
    margin-top: 30px;
    padding-left: 75px;
    padding-right: 75px;

    .menu {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
      cursor: pointer;
      color: #3f3f3f;
      &.active {
        color: #13b0e1;
      }
    }

    .VueCarousel-slide {
      text-align: center;
    }
  }
  .masker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
