<template>
  <div class="section">
    <el-row
      class="items"
      v-masonry
      transition-duration="0.3s"
      item-selector=".item"
    >
      <el-col :span="8">
        <div
          v-masonry-tile
          class="item"
          v-for="(item, idx) in items"
          :key="'album-' + idx"
        >
          <el-image
            class="el-img"
            :lazy="true"
            :src="item.thumb"
            :preview-src-list="[item.big]"
            @load="imageLoaded"
          >
          </el-image>
        </div>
      </el-col>
    </el-row>

    <!-- <div
      v-masonry
      transition-duration="0.3s"
      :gutter="20"
      item-selector=".item"
    >
      <div v-masonry-tile class="item" v-for="item in items" :key="item.id">
        <el-image
          class="el-img"
          :lazy="true"
          :src="item.thumb"
          :preview-src-list="[item.thumb]"
          @load="imageLoaded"
        >
        </el-image>
      </div>
    </div> -->
  </div>
</template>

<script>
import LoadMore from "./LoadMore.vue";
export default {
  name: "AlbumList",
  components: {
    LoadMore,
  },
  methods: {
    imageLoaded() {
      this.$redrawVueMasonry();
    },
  },
  props: {
    hasmore: {
      default: false,
    },

    items: {
      default: [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin-left: 200px;
  margin-right: 200px;
}

.col-with {
  width: 23px;
}

.items {
  .item {
    width: 33.333333%;
    // margin-bottom: 23px;
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>
