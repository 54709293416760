<template>
  <div class="section header">
    <el-row type="flex" align="middle" justify="space-between">
      <el-col>
        <router-link to="/">
          <img
            src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/logo1.png"
            alt="Leadex"
            class="logo"
          />
        </router-link>
      </el-col>
      <el-col>
        <div class="menutoggle">
          <img
            src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-open.png"
            alt="Leadex"
            class="menuhome"
          />
        </div>
      </el-col>
      <el-col class="menus-wrap">
        <div class="menus">
          <div class="menu search"></div>
          <div class="menu views"></div>
          <div class="menu cart"></div>
          <div class="menu ali"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.menutoggle {
  display: flex;
  justify-content: center;
}
.menuhome {
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.header {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  background-color: #fff;
}
.logo {
  width: 125px;
  height: 40px;
  margin-left: 160px;
}

.menus-wrap {
  text-align: right;
}
.menus {
  display: flex;
  justify-content: flex-end;
  margin-right: 160px;
}
.menu {
  width: 33px;
  height: 33px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 33px;
  &.search {
    background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-search.png);
  }
  &.ali {
    background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-ali.png);
  }
  &.views {
    background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-views.png);
  }
  &.cart {
    background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-cart.png);
  }
}
</style>
