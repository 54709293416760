<template>
  <el-row class="menus" type="flex">
    <el-col class="group">
      <div class="group-nav clickable" @click="goSeriesPage">Series</div>
      <div class="items">
        <div class="item" v-for="serie in series" :key="serie.id">
          <router-link :to="{ name: 'Series', params: { id: serie.id } }">{{
            serie["name"]
          }}</router-link>
        </div>
      </div>
    </el-col>
    <el-col class="group" v-for="scene in scenes" :key="scene.id">
      <div class="group-nav" v-html="scene.name"></div>
      <div class="items">
        <div class="item" v-for="sub in scene.subs" :key="sub.id">
          <router-link :to="{ name: 'Scene', params: { id: sub.id } }">{{
            sub["name"]
          }}</router-link>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
.group {
  font-family: "Roboto";
  font-style: normal;
  margin-top: 45px;

  &-nav {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 32px;
  }

  .items {
    .item {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;

      a {
        text-decoration: none;
        color: #3f3f3f;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "MenuProucts",
  computed: {
    ...mapGetters({
      series: "getSeries",
      scenes: "getScenes",
    }),
  },
  methods: {
    goSeriesPage() {
      if (this.$route.name === "SerieList") return;
      this.$router.push({ name: "SerieList" });
    },
  },
};
</script>
