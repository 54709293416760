<template>
  <div class="container module">
    <page-header v-if="banner" type="image" :height="750" :image="banner">
      <template v-slot:searchbox>
        <div class="search-form">
          <div class="title">Module</div>
          <el-input class="search-input" placeholder="keywords"></el-input>
        </div>
      </template>
    </page-header>

    <div class="navi">
      <PageNavbar class="inn" />
    </div>

    <filters @on-search="onFilter" :data="filterData" :total="total"> </filters>

    <div class="section-module">
      <ModuleList :items="items" />
    </div>

    <div class="section">
      <div class="loadmore" v-if="hasmore">
        <div class="btn" @click="loadMore">Load More</div>
      </div>
    </div>

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.module {
  background-color: #fff;
}

.section-module {
  margin-left: 210px;
  margin-right: 210px;
  padding-bottom: 300px;
}

.navi {
  position: relative;
  display: flex;
  justify-content: center;

  .inn {
    margin-top: -75px;
  }
}

.search-input {
  width: 350px;
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/FiltersV2.vue";
import ModuleList from "../components/ModuleList.vue";
import { mapGetters } from "vuex";
import Api from "../utils/api";

export default {
  name: "Module",
  components: {
    PageFooter,
    PageHeader,
    PageNavbar,
    Filters,
    ModuleList,
  },
  computed: {
    ...mapGetters({
      getProductCats: "getProductCats",
      getModuleTypes: "getModuleTypes",
      getBrands: "getBrands",
      getPageData: "getPageData",
    }),
    filterData() {
      return {
        type: "group",
        items: [
          {
            name: "Product Category",
            items: this.getProductCats,
            cat: "scene_cat",
            column: "two",
          },
          {
            name: "Brand",
            cat: "series_cat",
            items: this.getBrands,
            column: "two",
          },
          {
            name: "File Type",
            cat: "module_type",
            items: this.getModuleTypes,
            column: "one",
          },
        ],
      };
    },
    banner() {
      let key = "modules";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },
  methods: {
    onFilter(args) {
      let options = {
        pg: 1,
        scene_cat_id: args.selected_col1,
        series_cat_id: args.selected_col2,
        module_type_id: args.selected_col3,
      };
      this.queryItems(options, true);
    },

    loadMore() {
      if (this.hasmore) {
        this.queryItems({ pg: this.pg });
      }
    },
    queryItems(args, replace = false) {
      Api.getModuleList(args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }

        this.total = res.total;
        this.hasmore = res.hasmore;
        if (this.hasmore) {
          this.pg = Number(res.pg) + 1;
        }
      });
    },
  },
  created() {
    this.queryItems({ pg: 1 });
  },

  data() {
    return {
      showFilter: false,
      brand: "",
      total: 0,
      pg: 1,
      hasmore: false,
      items: [],
    };
  },
};
</script>
