import Vue from "vue";

import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import AboutUs from "../views/AboutUs.vue";
import Module from "../views/Module.vue";
import Image from "../views/Image.vue";
import Video from "../views/Video.vue";
import Catalogue from "../views/Catalogue.vue";

import Series from "../views/Series.vue";
import SerieScene from "../views/SerieScene.vue";

import Scene from "../views/Scene.vue";
import SerieList from "../views/SerieList.vue";

import Material from "../views/Material.vue";
import Wood from "../views/Wood.vue";
import Metal from "../views/Metal.vue";
import Fabric from "../views/Fabric.vue";
import FabricCat from "../views/FabricCat.vue";
import Single from "../views/Single.vue";
import Idea from "../views/Idea.vue";
import Service from "../views/Service.vue";

import Projects from "../views/Projects.vue";
import SingleProject from "../views/SingleProject.vue";

import SingleNews from "../views/SingleNews.vue";
import SingleIdea from "../views/SingleIdea.vue";

import SeriesDetail from "../views/SeriesDetail.vue"
import SeriesAlbum from "../views/SeriesAlbum.vue"
import SeriesVideo from "../views/SeriesVideo.vue"
import SeriesModel from "../views/SeriesModel.vue"
import SeriesCatalog from "../views/SeriesCatalog.vue"

import NewsList from "../views/NewsList.vue"
// import NewsDetail from "../views/NewsDetail.vue"

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/series", name: "SerieList", component: SerieList },
  { path: "/series/:id/scene/:scene", name: "SerieScene", component: SerieScene },

  { path: "/news", name: "NewsList", component: NewsList },
  { path: "/news/:id", name: "NewsDetail", component: SingleNews },

  {
    path: "/series/:id",
    name: "Series",
    component: Series,
    redirect: '/series/:id/detail',
    children: [
      { path: 'detail', name: 'SeriesDetail', component: SeriesDetail },
      { path: 'album', name: 'SeriesAlbum', component: SeriesAlbum },
      { path: 'video', name: 'SeriesVideo', component: SeriesVideo },
      { path: 'model', name: 'SeriesModel', component: SeriesModel },
      { path: 'catalog', name: 'SeriesCatalog', component: SeriesCatalog },

    ]
  },
  { path: "/scene/:id", name: "Scene", component: Scene },
  { path: "/products/:id", name: "Products", component: Single },

  { path: "/material", name: "Material", component: Material },

  { path: "/woods", name: "Wood", component: Wood },
  { path: "/fabric", name: "Fabric", component: Fabric },
  { path: "/fabric/:id", name: "FabricCat", component: FabricCat },
  { path: "/metal", name: "Metal", component: Metal },
  { path: "/module", name: "Module", component: Module },
  { path: "/image", name: "Image", component: Image },
  { path: "/video", name: "Video", component: Video },
  { path: "/catalogue", name: "Catalog", component: Catalogue },
  { path: "/service", name: "Service", component: Service },
  { path: "/about", name: "About", component: About },
  { path: "/about-us", name: "AboutUs", component: AboutUs },

  { path: "/projects", name: "Projects", component: Projects },
  { path: "/projects/:id", name: "SingleProject", component: SingleProject },

  { path: "/news/:id", name: "SingleNews", component: SingleNews },

  { path: "/idea", name: "Idea", component: Idea },
  { path: "/idea/:id", name: "SingleIdea", component: SingleIdea },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


import store from '../store/index'

router.afterEach((to, from, next) => {
  window, scrollTo(0, 0)
  store.commit('hideMegaMenu')
  store.commit('hidePageFilter')
})

// router.beforeEach((to, from) => {
//   
// })

export default router;
