<template>
  <div class="about container">
    <PageHeader v-if="banner" color="white" :height="1160" :image="banner" />
    <div class="list-box">
      <el-row :gutter="50" class="items">
        <el-col :span="8">
          <div class="item" @click="goPage('woods')" v-if="woods">
            <div class="item-thumb">
              <el-image :src="woods" lazy-load class="item-thumb__url" />
            </div>
            <div class="item-title">Woods</div>
          </div>
        </el-col>
        <!--  -->
        <el-col :span="8">
          <div class="item" @click="goPage('fabric')" v-if="fabric">
            <div class="item-thumb">
              <el-image :src="fabric" lazy-load class="item-thumb__url" />
            </div>
            <div class="item-title">Fabric</div>
          </div>
        </el-col>
        <!--  -->
        <el-col :span="8">
          <div class="item" @click="goPage('metal')" v-if="metal">
            <div class="item-thumb">
              <el-image :src="metal" lazy-load class="item-thumb__url" />
            </div>
            <div class="item-title">Metal</div>
          </div>
        </el-col>

        <!--  -->
      </el-row>
    </div>

    <page-footer />
  </div>
</template>

<style lang="scss" scoped>
.box {
  padding-top: 275px;
  background-color: #fff;
  padding-bottom: 210px;
}

.list-box {
  margin: 200px 160px;
}
.items {
  display: flex;

  margin-bottom: 288px;
  justify-content: space-between;

  .item {
    border: 1px solid #000000;
    cursor: pointer;

    &-thumb__url {
      width: 100%;
    }

    &-title {
      padding-top: 60px;
      padding-bottom: 60px;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
      color: #3f3f3f;
      text-align: center;
    }
  }
}
</style>

<script>
import Api from "../utils/api";
import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import ComHeader from "../components/ComHeader.vue";
import ComBlocks from "../components/ComBlocks.vue";
import { mapGetters } from "vuex";
export default {
  name: "ColorAndMaterial",
  components: {
    PageFooter,
    PageHeader,
    ComBlocks,
    ComHeader,
  },

  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "material";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
    woods() {
      let key = "woods_cat";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
    fabric() {
      let key = "fabric_cat";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
    metal() {
      let key = "metal_cat";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  methods: {
    goPage(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>
