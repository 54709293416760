<template>
  <div class="section header-box">
    <page-header />

    <div class="type-image" v-if="isType == 'image'">
      <el-image :lazy="true" :src="image"></el-image>
      <!-- <div class="search-form">search form</div> -->
      <div class="masker">
        <div class="search-bar">
          <div class="catname">{{ title }}</div>
          <div class="searchform">
            <el-input placeholder="keywords" suffix-icon="el-icon-search">
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="type-video" v-if="isType === 'video'">{{ video }}</div>
  </div>
</template>

<script>
import PageHeader from "./PageHeader.vue";
export default {
  name: "ComHeader",
  components: {
    PageHeader,
  },
  computed: {
    isType() {
      return this.video ? "video" : "image";
    },
  },
  props: {
    title: {
      default: 'custom title name'
    },
    image: {
      type: String,
      default: null,
    },
    video: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  text-align: center;

  .catname {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
  }

  .searchform {
    margin-top: 30px;
  }
}

.type-image {
  position: relative;

  .masker {
    background-color: rgba($color: #000000, $alpha: 0.07);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.inner {
  position: relative;
  background-color: red;
}
</style>
