<template>
  <div class="container module">
    <page-header v-if="banner" type="image" :height="750" :image="banner">
      <template v-slot:searchbox>
        <div class="search-form">
          <div class="title">Image</div>
          <el-input
            style="width: 350px"
            placeholder="Keywords goes here"
          ></el-input>
        </div>
      </template>
    </page-header>

    <div class="navi">
      <page-navbar class="inn" />
    </div>

    <filters @on-search="onFilter" :data="filterData" :total="total"> </filters>

    <album-list :items="items" />

    <div class="section">
      <div class="loadmore" v-if="hasmore">
        <div class="btn" @click="loadMore">Load More</div>
      </div>
    </div>

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.module {
  background-color: #fff;
}
</style>

<script>
import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import ComHeader from "../components/ComHeader.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/FiltersV2.vue";
import AlbumList from "../components/AlbumList.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "Album",
  components: {
    PageFooter,
    ComHeader,
    PageNavbar,
    Filters,
    AlbumList,
    PageHeader,
  },
  methods: {
    onFilter(args) {
      this.scene_cat_id = args.selected_col1;
      this.series_cat_id = args.selected_col2;

      let options = {
        pg: 1,
        scene_cat_id: args.selected_col1,
        series_cat_id: args.selected_col2,
      };
      this.queryItems(options, true);
    },

    loadMore() {
      if (this.hasmore) {
        let options = {
          pg: this.pg,
          series_cat_id: this.series_cat_id,
          scene_cat_id: this.scene_cat_id,
        };
        this.queryItems(options);
      }
    },

    queryItems(args, replace = false) {
      Api.getAlbumList(args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }
        this.total = res.total;
        this.hasmore = res.hasmore;
        if (res.hasmore) {
          this.pg = Number(res.pg) + 1;
        }
      });
    },
  },
  created() {
    this.queryItems({ pg: 1 });
  },
  computed: {
    ...mapGetters({
      getProductCats: "getProductCats",
      types: "getTypeCats",
      getBrands: "getBrands",
      getPageData: "getPageData",
    }),
    filterData() {
      return {
        type: "group",
        items: [
          {
            name: "Product Category",
            items: this.getProductCats,
            cat: "scene_cat",
            column: "three",
          },
          {
            name: "Brand",
            cat: "series_cat",
            items: this.getBrands,
            column: "two",
          },
        ],
      };
    },
    banner() {
      let key = "images";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  data() {
    return {
      showFilter: false,
      brand: "",
      scene_cat: 0,
      type_cat: 0,
      total: 0,
      hasmore: false,
      items: [],
      scene_cat_id: 0,
      series_cat_id: 0,
    };
  },
};
</script>
