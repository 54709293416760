<template>
  <div class="spec">
    <div class="section dimensisions">
      <div class="section-nav">
        <i class="el-icon-arrow-right"></i>
        Dimensions
      </div>
      <div class="section-content">
        <el-image
          class="spec-img"
          lazy
          v-for="(img, idx) in dimension_imgs"
          :key="'dim' + idx"
          :src="img"
        >
        </el-image>
      </div>
    </div>
    <!--  -->

    <div class="section p-and-d">
      <div class="section-nav">
        <i class="el-icon-arrow-right"></i>
        Power and Data
      </div>
      <div class="section-content">
        <el-image
          class="pad-image"
          lazy
          v-for="(img, idx) in pnd_imgs"
          :key="'pnd' + idx"
          :src="img"
        >
        </el-image>
      </div>
    </div>

    <!--  -->

    <div
      class="section materials"
      v-if="
        metal_ids.length > 0 || wood_ids.length > 0 || fabric_ids.length > 0
      "
    >
      <div class="section-nav">
        <i class="el-icon-arrow-right"></i>
        Materials
      </div>

      <div class="section-content">
        <div class="rows" v-if="wood_ids.length > 0">
          <div class="header">
            <div class="nav">Woods</div>
            <div class="more">
              <a @click="goToPage('woods')" v-if="wood_ids.length >= 5">
                View More <i class="el-icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <el-row class="items" :gutter="10">
            <el-col
              class="item"
              v-for="(item, idx) in wood_ids"
              :key="'w' + idx"
            >
              <div class="item-thumb">
                <el-image
                  lazy
                  :src="item.thumb"
                  :preview-src-list="[item.full]"
                >
                </el-image>
              </div>
              <div class="item-title">{{ item.title }}</div>
            </el-col>
          </el-row>
        </div>
        <!--  -->

        <div class="rows" v-if="fabric_ids.length > 0">
          <div class="header">
            <div class="nav">Fabric</div>
            <div class="more">
              <a @click="goToPage('fabric')" v-if="fabric_ids.length >= 5">
                View More <i class="el-icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <el-row class="items" :gutter="10">
            <el-col
              class="item"
              v-for="(item, idx) in fabric_ids"
              :key="'f' + idx"
            >
              <div class="item-thumb">
                <el-image
                  lazy
                  :src="item.thumb"
                  :preview-src-list="[item.full]"
                >
                </el-image>
              </div>
              <div class="item-title">{{ item.title }}</div>
            </el-col>
          </el-row>
        </div>
        <!--  -->

        <div class="rows" v-if="metal_ids.length > 0">
          <div class="header">
            <div class="nav">Metal</div>
            <div class="more">
              <a @click="goToPage('metal')" v-if="metal_ids.length >= 5">
                View More <i class="el-icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <el-row class="items" :gutter="10">
            <el-col
              class="item"
              v-for="(item, idx) in metal_ids"
              :key="'m' + idx"
            >
              <div class="item-thumb">
                <el-image
                  lazy
                  :preview-src-list="[item.full]"
                  :src="item.thumb"
                >
                </el-image>
              </div>
              <div class="item-title">{{ item.title }}</div>
            </el-col>
          </el-row>
        </div>

        <!--  -->
      </div>
    </div>
    <!--  -->
  </div>
</template>
<script>
export default {
  name: "Spec",
  props: {
    pnd_imgs: {
      type: Array,
      default: () => [],
    },
    dimension_imgs: {
      type: Array,
      default: () => [],
    },
    wood_ids: {
      type: Array,
      default: () => [],
    },
    fabric_ids: {
      type: Array,
      default: () => [],
    },
    metal_ids: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToPage(path) {
      // window.location =
      this.$router.push({ path: "/" + path });
    },
  },
};
</script>
<style lang="scss" scoped>
.materials {
  margin-top: 100px;
  margin-bottom: 200px;
}

.p-and-d {
  margin-top: 100px;
}

.section {
  &-nav {
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    color: #3f3f3f;
    display: flex;
    align-items: center;

    ::v-deep .el-icon-arrow-right {
      font-size: 20px;
      margin-right: 10px;
      font-weight: bold;
    }
  }

  &-content {
    .material-image {
      margin-top: 88px;
      margin-bottom: 88px;
    }

    .rows {
      margin-left: 120px;
      margin-top: 22px;

      margin-right: 70px;

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;

        .nav {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          color: #3f3f3f;
        }

        .more {
          a {
            text-decoration: none;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #3f3f3f;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .items {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        .item {
          text-align: center;
          width: 20%;
          margin-bottom: 50px;
          &-title {
            margin-top: 15px;
          }

          &-thumb {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
