<template>
  <div class="seciton module-list">
    <el-row type="flex" class="items" :gutter="45">
      <el-col
        class="item"
        :span="6"
        v-for="(item, item_index) in items"
        :key="'module-' + item_index"
      >
        <div class="item-inner">
          <div class="download">
            <img
              @click="dlFile(item)"
              class="icon-img"
              title="Download File"
              src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-download.png"
            />
          </div>
          <div class="item-thumb">
            <img :src="item.cover" />
          </div>
          <div class="item-name" v-html="item.title"></div>
          <!-- <div class="item-metas">
            <el-row type="flex" class="metas" align="middle">
              <el-col :span="8" class="meta meta-select">
                <img
                  class="icon-img"
                  src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-selected.png"
                />
              </el-col>
              <el-col :span="8" class="meta meta-select">
                <div @click="dlFile(item)">
                  <img
                    class="icon-img"
                    src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-download.png"
                  />
                </div>
              </el-col>
              <el-col :span="8" class="meta meta-select">
                <img
                  class="icon-img"
                  src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-share.png"
                />
              </el-col>
            </el-row>
          </div> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss" scoped>
.module-list {
  padding-bottom: 80px;
  img {
    max-width: 100%;
  }
}

.items {
  flex-wrap: wrap;

  .metas {
    border-top: 1px solid #bfc2c2;

    .meta {
      padding: 20px;
      text-align: center;

      img {
        // cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }
  }

  .item {
    position: relative;

    &-name {
      // margin-top: 60px;
      text-align: center;
      margin-bottom: 35px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #3f3f3f;
      padding-bottom: 30px;
    }

    &-thumb {
      text-align: center;
      // cursor: pointer;
    }

    &-inner {
      margin-bottom: 58px;
      padding-top: 26px;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      transition: all 0.2s ease-in-out;

      position: relative;

      .download {
        position: absolute;
        width: 40px;
        display: none;
        height: 40px;
        cursor: pointer;
        // background-color: red;
        opacity: 0.6;
        right: 10px;
        top: 10px;
        transition: all ease-in-out 0.2s;
      }

      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.45);

        .download {
          display: block;
        }
      }
    }
  }
}
</style>
<script>
export default {
  name: "ModuleList",
  components: {},
  props: {
    items: {
      default: [],
    },
  },
  methods: {
    dlFile(item) {
      window.open(item.file);
    },
  },
  data() {
    return {};
  },
};
</script>
