<template>
  <div class="about container">
    <PageHeader :height="1080" type="image" v-if="banner" :image="banner" />

    <div class="filter">{{ total }} Results</div>
    <div class="section products-wrap">
      <el-row :gutter="45" class="items" type="flex">
        <el-col class="item" v-for="(item, idx) in items" :key="item.id">
          <div class="item-thumb">
            <el-image
              :preview-src-list="[item.full]"
              class="item-thumb__url"
              :src="item.thumb"
            >
            </el-image>
          </div>
          <div class="item-title">{{ item.title }}</div>
        </el-col>
      </el-row>
    </div>

    <page-footer />
  </div>
</template>

<style lang="scss" scoped>
.filter {
  margin: 100px 0;
  text-align: right;
  padding-right: 210px;
}
.section {
  padding-left: 210px;
  padding-right: 210px;
}

.products-wrap {
  padding-bottom: 200px;
}
.items {
  flex-wrap: wrap;

  .item {
    width: 20%;
    margin-bottom: 45px;
    text-align: center;
    cursor: pointer;

    &-title {
      margin-top: 25px;

      font-weight: 400;
      font-size: 18px;

      color: #3f3f3f;
    }
  }
}
</style>

<script>
import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "Metal",
  components: {
    PageFooter,
    PageHeader,
  },
  data() {
    return {
      hasmore: false,
      total: 0,
      items: [],
    };
  },

  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "metal";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  methods: {
    getItems(args) {
      Api.getMetal(args).then((res) => {
        this.items = res.items;
        this.total = res.total;
        this.hasmore = !!res.hasmore;
      });
    },
  },
  created() {
    this.getItems({ pg: 1 });
  },
};
</script>
