<template>
  <div class="items">
    <el-row
      class="item"
      v-for="(item, idx) in items"
      :key="'x' + idx"
      :gutter="30"
      :class="idx % 2 == 0 ? 'odd' : ''"
    >
      <el-col class="left" :span="8">
        <div class="title">{{ item.title }}</div>
        <div class="subtitle">{{ item.subtitle }}</div>
        <div class="desc" v-html="item.desc"></div>
      </el-col>
      <el-col class="right" :span="16">
        <el-image :src="item.img" lazy />
      </el-col>
    </el-row>
    <!--  -->
  </div>
</template>
<script>
export default {
  name: "OverView",
  props: {
    items: {
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.items {
  .item {
    display: flex;

    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    margin-bottom: 200px;

    &.odd {
      flex-direction: row-reverse;
    }

    .left {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 50px;
        line-height: 59px;
      }

      .desc {
        margin-top: 100px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
      }

      .subtitle {
        margin-top: 40px;
        font-size: 25px;
        line-height: 29px;
      }
    }

    .right {
      img {
        width: 100%;
        // width: 977px;
        // height: 652px;
      }
    }
  }
}
</style>
