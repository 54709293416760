<template>
  <div class="filters">
    <el-row class="fiters-box" type="flex" align="middle">
      <el-col class="left">
        <div
          class="label"
          :class="[showFilter ? 'open' : 'close']"
          @click="toggleStatus"
        >
          Filters
        </div>
        <el-popover :value="showFilter">
          <div class="box">
            <div class="flex">
              <!--  -->
              <div class="flex-item" v-for="cat in data.items" :key="cat.id">
                <div class="fg list-columns">
                  <div class="fg-body">
                    <div class="items" :class="cat.column + '-columns'">
                      <div
                        class="item"
                        v-for="subcat in cat.items"
                        :key="subcat.id"
                      >
                        <el-radio v-model="selected_col1" :label="subcat.id">{{
                          subcat.name
                        }}</el-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
          <!--  -->
        </el-popover>
      </el-col>
      <el-col class="right"> {{ total }} Results </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PageFilters",
  data() {
    return {
      selected_col1: "",
    };
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },

    is_single: {
      type: Boolean,
      default: false,
    },

    total: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    ...mapState(["showFilter"]),
  },

  watch: {
    selected_col1(val) {
      this.$emit("on-search", val);
      this.$store.commit("hidePageFilter");
    },
  },
  methods: {
    // --- ---
    // --- ---
    doSimpleSearch(cat) {
      this.$emit("on-search", cat.id);
      this.$store.commit("hidePageFilter");
    },

    // --- ---
    // --- ---
    toggleStatus() {
      this.$store.commit("togglePageFilter");
    },

    // --- ---
    // --- ---
    applyHandler() {
      let args = {
        selected_col1: this.selected_col1,
        selected_col2: this.selected_col2,
        selected_col3: this.selected_col3,
      };
      this.$emit("on-search", args);
      this.$store.commit("hidePageFilter");
    },

    // --- ---
    // --- ---
    resetHandler() {
      this.selected_col1 = "";
      this.selected_col2 = "";
      this.$emit("resetItems");
      this.$store.commit("hidePageFilter");
    },
    // --- ---
    // --- ---
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  padding: 45px 85px;
}

.simple-items {
  padding-top: 25px;
  padding-bottom: 25px;
  .item {
    width: 235px;
    text-align: center;
    padding: 0 0;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #13b0e1;
    }
  }
}

.fg {
  // padding: 45px 85px 15px 85px;

  &.list-brands {
    .subitem {
      width: 100px;
    }
  }

  &-nav {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3f3f3f;
  }
  &-body {
    margin-top: 35px;
    .two-columns {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
    }
    .three-columns {
      display: flex;
      flex-wrap: wrap;
      max-width: 720px;
    }
    .item,
    .subitem {
      margin-bottom: 35px;
      width: 235px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */

      display: flex;
      align-items: center;

      color: #3f3f3f;
    }
  }
}

.btns {
  position: relative;
  display: flex;
  justify-content: center;
  // padding-top: 35px;
  padding-bottom: 35px;
  .btn {
    width: 190px;
    height: 30px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 30px;
    background: #000000;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.7);
    }
    &:last-child {
      margin-left: 38px;
    }
  }
}

.filters {
  position: relative;
  margin-top: 117px;
  margin-bottom: 90px;
  .left {
    font-family: "Roboto";
    display: flex;
    padding-left: 210px;

    .filter-inner {
      padding: 20px 60px;
      .cat {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
      }

      .items {
        flex-wrap: wrap;

        .item {
          margin-top: 35px;
        }
      }
    }

    .label {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;

      background-size: 24px 24px;
      background-repeat: no-repeat;
      padding-right: 30px;
      cursor: pointer;
      background-position-y: center;
      background-position-x: right;

      &.close {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-filters.png);
      }
      &.open {
        background-image: url(//leadexv2.weappdev.cn/wp-content/uploads/sites/77/2022/08/icon_surprise.png);
      }
    }
  }
  .right {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    padding-right: 210px;
  }
}
</style>
