<template>
  <div class="container module">
    <PageHeader :height="1080" type="image" v-if="!!banner" :image="banner" />

    <div class="sction pagenav">
      <PageNav />
    </div>

    <router-view></router-view>

    <page-footer />
  </div>
</template>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNav from "../components/PageNav.vue";
import Filters from "../components/Filters.vue";
import AlbumList from "../components/AlbumList.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "Series",
  components: {
    PageFooter,
    PageNav,
    PageHeader,
    Filters,
    AlbumList,
  },
  computed: {
    // ...mapGetters(["getPageData"]),
    // banner() {
    //   let key = "about";
    //   let page = this.getPageData;
    //   return !!page[key] ? page[key] : false;
    // },
  },
  methods: {
    getSeries(id) {
      Api.getSeries(id).then((res) => {
        console.log(" cat", res);
        this.$store.commit("setSeriesDetailItems", res.items);
        this.items = res.items;
        this.banner = res.banner;
        this.cat = res.cat;
      });
    },
  },
  watch: {
    $route: function (nv, ov) {
      let id = nv.params.id;
      this.getSeries(id);
    },
  },
  created() {
    const { id } = this.$route.params;
    this.getSeries(id);
  },
  data() {
    return {
      showFilter: false,
      cat: {},
      banner: "",
      items: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.pagenav {
  position: relative !important;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.module {
  background-color: #fff;
}

.navi {
  position: relative;
  display: flex;
  justify-content: center;

  .inn {
    margin-top: -75px;
  }
}
</style>
