<template>
  <div class="about container">
    <page-header
      v-if="banner"
      color="white"
      type="image"
      :image="banner"
      :height="1080"
    >
    </page-header>

    <div class="news">
      <div class="news-title">
        <h1>{{ news.title }}</h1>
      </div>
      <div class="news-entry" v-html="news.content"></div>
    </div>
    <page-footer />
  </div>
</template>

<style lang="scss" scoped>
.news {
  &-title {
    margin-bottom: 50px;
    font-size: 32px;
  }
  padding: 210px;
  background-color: #fff;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    width: 510px;
    height: 340px;
    cursor: pointer;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    transition: all 0.3s ease-in-out;
    &:nth-of-type(2n) {
      margin-left: 70px;
    }

    &:hover {
      background: rgba($color: #ddd, $alpha: 0.5);
    }

    &-thumb {
      margin-bottom: 32px;
    }

    &-img {
      width: 80px;
    }

    &-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;

      color: #3f3f3f;
    }
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";

import { mapGetters } from "vuex";
import Api from "../utils/api";
export default {
  name: "About",
  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "service";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  created() {
    let id = this.$route.params.id;
    Api.getNewsDetail(id).then((res) => {
      this.news = res.news;
    });
  },

  methods: {
    goItem(item) {
      if (!!item.url) {
        window.location.href = url;
      }
    },
  },
  data() {
    return {
      news: {},
    };
  },
  components: {
    PageFooter,
    PageHeader,
  },
};
</script>
