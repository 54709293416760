<template>
  <div class="about container">
    <PageHeader :height="1080" type="video" />

    <!--  -->
    <div class="section section-maincolor">
      <div class="section-nav">Main Colour</div>

      <div class="list-box">
        <el-row :gutter="50" class="items">
          <el-col :span="6" v-for="(item, idx) in mainItems" :key="item.id"
            ><div class="grid-content bg-purple">
              <div class="item-meta">
                <div
                  class="item-thumb flip-container"
                  :class="[item.flip ? 'hover' : '']"
                >
                  <div class="flipper">
                    <div class="font">
                      <el-image :src="item.img_font" fix="cover"></el-image>
                    </div>
                    <div class="back">
                      <el-image :src="item.img_back" fix="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div class="item-btns">
                  <div
                    class="btn btn-list"
                    @click="toggleShowInfo(idx, 'main')"
                  ></div>
                  <div class="btn btn-play" @click="playVideo(item)"></div>
                  <div
                    class="btn btn-refresh"
                    @click="toggleBackImg(idx, 'main')"
                  ></div>
                </div>
                <Transition name="fade">
                  <div class="item-masker" v-show="item.showinfo">
                    <div
                      class="item-masker-del"
                      @click="toggleShowInfo(idx, 'main')"
                    ></div>

                    <div class="item-infos">
                      <div class="item-info-title" v-if="item.model">
                        {{ item.model }}
                      </div>
                      <div class="item-info-subtitle">{{ item.title }}</div>
                      <div class="item-info-size" v-if="item.h1">
                        <div class="size-icon">
                          <el-image
                            src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/Group-45.png"
                          ></el-image>
                        </div>
                        <div>
                          <div v-if="item.h1">{{ item.h1 }}</div>
                          <div v-if="item.h2">{{ item.h2 }}</div>
                          <div v-if="item.h3">{{ item.h3 }}</div>
                        </div>
                      </div>
                      <div type="flex" class="item-info-size" v-if="item.v1">
                        <div class="size-icon">
                          <el-image
                            src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/Group-44.png"
                          ></el-image>
                        </div>
                        <div>
                          <div v-if="item.v1">{{ item.v1 }}</div>
                          <div v-if="item.v2">{{ item.v2 }}</div>
                          <div v-if="item.v3">{{ item.v3 }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
              <div class="item-title">{{ item.title }}</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!--  -->
    <div class="section section-maincolor pb228">
      <div class="mt190 section-nav">Auxiliary Colour</div>

      <div class="list-box">
        <el-row :gutter="50" class="items">
          <el-col :span="6" v-for="(item, idx) in auxiliaryItems" :key="item.id"
            ><div class="grid-content bg-purple">
              <div class="item-meta">
                <div
                  class="item-thumb flip-container"
                  :class="[item.flip ? 'hover' : '']"
                >
                  <div class="flipper">
                    <div class="font">
                      <el-image :src="item.img_font" fix="cover"></el-image>
                    </div>
                    <div class="back">
                      <el-image :src="item.img_back" fix="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div class="item-btns">
                  <div
                    class="btn btn-list"
                    @click="toggleShowInfo(idx, 'auxiliary')"
                  ></div>
                  <div class="btn btn-play" @click="playVideo(item)"></div>
                  <div
                    class="btn btn-refresh"
                    @click="toggleBackImg(idx, 'auxiliary')"
                  ></div>
                </div>
                <Transition name="fade">
                  <div class="item-masker" v-show="item.showinfo">
                    <div
                      class="item-masker-del"
                      @click="toggleShowInfo(idx, 'auxiliary')"
                    ></div>

                    <div class="item-infos">
                      <div class="item-info-title" v-if="item.model">
                        {{ item.model }}
                      </div>
                      <div class="item-info-subtitle">{{ item.title }}</div>
                      <div class="item-info-size" v-if="item.h1">
                        <div class="size-icon">
                          <el-image
                            src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/Group-45.png"
                          ></el-image>
                        </div>
                        <div v-if="item.h1">{{ item.h1 }}</div>
                        <div v-if="item.h2">{{ item.h2 }}</div>
                        <div v-if="item.h3">{{ item.h3 }}</div>
                      </div>
                      <div type="flex" class="item-info-size" v-if="item.v1">
                        <div class="size-icon">
                          <el-image
                            src="//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/Group-44.png"
                          ></el-image>
                        </div>
                        <div>
                          <div v-if="item.v1">{{ item.v1 }}</div>
                          <div v-if="item.v2">{{ item.v2 }}</div>
                          <div v-if="item.v3">{{ item.v3 }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
              <div class="item-title">{{ item.title }}</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <page-footer />

    <el-dialog
      :visible.sync="showPlayerForm"
      width="50%"
      center
      @close="hideVideoForm"
      :show-close="false"
    >
      <div class="player">
        <video ref="vv" controls object-fit="cover" :src="video_url"></video>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 0;
}

.pb228 {
  padding-bottom: 228px;
}

.player {
  background-color: #000;

  video {
    max-width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// .flip-container:hover .flipper,
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

.section {
  //

  &-nav {
    margin-top: 230px;
    margin-bottom: 75px;
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    text-align: center;

    &.mt190 {
      margin-top: 150px;
    }
  }

  .list-box {
    margin-left: 210px;
    margin-right: 210px;
  }
  .items {
    margin: 0 210px;
    // display: flex;
    // flex-wrap: wrap;

    .item {
      // margin-right: 40px;
      margin-bottom: 45px;

      &-thumb {
        perspective: 1000;
      }
      &-title {
        margin-bottom: 55px;
      }
      &-meta {
        position: relative;
      }

      &-info {
        &-title {
          font-weight: 700;
          font-size: 25px;
        }

        &-subtitle {
          margin-top: 10px;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #3f3f3f;
          margin-bottom: 45px;
        }

        &-size {
          margin-bottom: 15px;
          display: flex;
          font-weight: 400;
          font-size: 18px;

          color: #3f3f3f;

          .size-icon {
            margin-right: 15px;
          }
        }
      }

      &-masker {
        position: absolute;
        left: 0;
        color: #3f3f3f;

        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #fff, $alpha: 0.5);

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-del {
          background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/fi_x.png);
          width: 33px;
          height: 33px;
          // background-color: red;
          position: absolute;
          cursor: pointer;
          right: 20px;
          top: 12px;
        }
      }

      &-btns {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;

        .btn {
          background-repeat: no-repeat;
          background-size: cover;
          width: 33px;
          height: 33px;
          cursor: pointer;

          &-list {
            background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/fi_list.png);
          }

          &-play {
            background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/fi_play-circle.png);
          }

          &-refresh {
            background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/fi_refresh-cw.png);
          }
        }
      }

      &-title {
        text-align: center;
        margin-top: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #3f3f3f;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
}

.box {
  padding-top: 275px;
  background-color: #fff;
  padding-bottom: 210px;
}
</style>

<script>
import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import ComHeader from "../components/ComHeader.vue";
import ComBlocks from "../components/ComBlocks.vue";
import Api from "../utils/api";
export default {
  name: "Wood",
  data() {
    return {
      showPlayerForm: false,
      video_url: "",
      mainItems: [],
      auxiliaryItems: [],
    };
  },

  methods: {
    hideVideoForm() {
      this.$refs.vv.pause();
    },
    playVideo(item) {
      this.video_url = item.video;
      this.showPlayerForm = true;
      this.$nextTick(() => {
        this.$refs.vv.play();
      });
    },
    toggleBackImg(idx, type) {
      let items = [];
      if (type === "main") {
        items = this.mainItems;
        items[idx]["flip"] = !items[idx]["flip"];
        this.mainItems = items;
      } else {
        items = this.auxiliaryItems;
        items[idx]["flip"] = !items[idx]["flip"];
        this.auxiliaryItems = items;
      }
    },
    toggleShowInfo(idx, type) {
      let items = [];
      if (type === "main") {
        items = this.mainItems;
        items[idx]["showinfo"] = !items[idx]["showinfo"];
        this.mainItems = items;
      } else {
        items = this.auxiliaryItems;
        items[idx]["showinfo"] = !items[idx]["showinfo"];
        this.auxiliaryItems = items;
      }
    },
  },
  created() {
    Api.getWoods().then((res) => {
      this.mainItems = res.main["items"];
      this.auxiliaryItems = res.auxiliary["items"];
    });
  },
  components: {
    PageFooter,
    PageHeader,
    ComBlocks,
    ComHeader,
  },
};
</script>
