<template>
  <div class="section video-list">
    <el-row class="videos" type="flex" :gutter="75" v-if="items.length">
      <el-col
        class="video"
        :span="8"
        v-for="(item, idx) in items"
        :key="'v' + idx"
      >
        <div class="video-box" @click="showPlayer(item)">
          <div class="video-masker"></div>
          <div class="video-cover">
            <img :src="item.cover" />
          </div>
        </div>
        <div class="video-name" v-html="item.title"></div>
      </el-col>
    </el-row>
    <div class="section empty" v-else>NO ITEMS</div>
    <el-dialog
      :visible.sync="showPlayerForm"
      width="60%"
      center
      @close="hideVideoForm"
      :show-close="false"
    >
      <div class="player">
        <video
          controls
          ref="vv"
          object-fit="cover"
          :poster="video_cover"
          :src="video_url"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LoadMore from "./LoadMore.vue";
export default {
  name: "VideoList",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    LoadMore,
  },
  methods: {
    hideVideoForm() {
      this.$refs.vv.pause();
    },
    showPlayer(item) {
      this.video_url = item.video;
      this.video_cover = item.cover;
      this.showPlayerForm = true;
      this.$nextTick(() => {
        this.$refs.vv.play();
      });
    },
  },
  data() {
    return {
      video_cover: null,
      video_url: null,
      showPlayerForm: false,
    };
  },
};
</script>
<style lang="scss">
.player {
  background-color: #000;
  video {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.section {
  &.empty {
    text-align: center;
    padding: 100px 0 200px 0;
  }
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 0;
}

.videos {
  flex-wrap: wrap;
  padding-left: 210px;
  padding-right: 210px;

  .video {
    margin-bottom: 120px;
    position: relative;

    &-name {
      text-align: center;
      margin-top: 35px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #3f3f3f;
    }

    &-box {
      position: relative;
    }

    &-masker {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.2s ease-in-out;
      background-color: rgba($color: #000000, $alpha: 0);
      cursor: pointer;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.4);
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
