<template>
  <div class="section shop">
    <div class="inner">
      <div class="title">ONLINE SHOP</div>
      <div class="button" @click="goUrl">SET OUT</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.inner {
  max-width: 1920px;
  height: 968px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/HIGH-TABLE-CAI-20210517-1.png);
  background-position: center center;

  .title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 117px;
    color: #fff;
  }

  .button {
    background: #000000;
    border-radius: 64px;
    width: 317px;
    height: 100px;
    display: flex;

    justify-content: center;
    align-items: center;

    // font-family: "Open Sans";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 16px;
    /* or 44% */
    color: #fff;

    letter-spacing: 0.75px;
    text-transform: uppercase;
  }
}
</style>
<script>
export default {
  name: "HomeShop",
  props: {
    url: {
      default: false,
    },
  },
  methods: {
    goUrl() {
      if (this.url) window.location.href = this.url;
    },
  },
};
</script>
