<template>
  <div class="section header" :class="customClass">
    <div class="back-box">
      <!--  -->
      <div class="swiper" v-if="type == 'swiper'">
        <el-carousel :interval="3000" arrow="hover" :class="customClass">
          <el-carousel-item
            v-for="(item, idx) in swipers"
            :key="'swiper_' + idx"
            :class="customClass"
          >
            <el-image :src="item" fit="cover"> </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--  -->
      <div class="video" v-else-if="type == 'video'">
        <transition name="fade">
          <div class="video-cover" v-show="videoStatus == 'pause'">
            <el-image
              v-show="videoStatusIcon == 'icon-pause'"
              class="video-btn"
              @click="videoStartStop"
              src="//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/06/icon-pause.png"
            >
            </el-image>

            <el-image
              v-show="videoStatusIcon == 'icon-playing'"
              class="video-btn"
              @click="videoStartStop"
              src="//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/06/icon-play.png"
            >
            </el-image>
          </div>
        </transition>
        <video
          ref="video"
          :controls="controls"
          src="//mwcdn.zhuoyu.co/wp-content/uploads/sites/61/2021/01/Growing-office_WeChat.mp4"
        ></video>
      </div>

      <!--  -->
      <div class="image" v-else>
        <el-image v-if="image" :src="image"> </el-image>
        <div class="content-box">
          <slot name="searchbox"></slot>
        </div>
      </div>
    </div>
    <!-- box -->
    <div class="font-box">
      <el-row
        class="main-menu"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col class="logo-box">
          <router-link to="/">
            <el-image class="logo-img" :src="menuAssets.logo" />
          </router-link>
        </el-col>
        <el-col class="center toggle">
          <el-image
            @click="toggleMenus"
            class="toggle-img"
            :src="menuAssets.plus"
          >
          </el-image>
        </el-col>
        <el-col class="menu-box">
          <div class="menus">
            <!-- <div
              class="menu search"
              :style="'background-image:url(' + menuAssets.search + ')'"
            ></div> -->
            <div
              class="menu views"
              @click="goLibraryPage"
              :style="'background-image:url(' + menuAssets.views + ')'"
            ></div>
            <!-- <div
              class="menu cart"
              :style="'background-image:url(' + menuAssets.cart + ')'"
            ></div> -->
            <div
              class="menu ali"
              @click="goServicePage"
              :style="'background-image:url(' + menuAssets.ali + ')'"
            ></div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- box -->

    <transition name="fade">
      <div class="mega-box" v-show="megaMenu">
        <div class="arrow">
          <div class="inner"></div>
        </div>
        <!--  -->
        <div class="mega-menus">
          <el-tabs v-model="activeMenu" :before-leave="clickMenu">
            <el-tab-pane label="PRODUCT" name="product">
              <menu-products></menu-products>
            </el-tab-pane>
            <el-tab-pane label="OFFICE IDEA" name="idea"
              >OFFICE IDEA</el-tab-pane
            >
            <el-tab-pane label="COLOR & METERIAL" name="material"></el-tab-pane>
            <el-tab-pane label="PROJECT" name="projects">PROJECTS</el-tab-pane>
            <el-tab-pane label="ABOUT" name="about">ABOUT</el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </transition>
    <!-- box -->
  </div>
</template>

<script>
import MenuProducts from "./menus/Products.vue";
import { mapState } from "vuex";

export default {
  name: "PageHeaderV2",
  components: {
    MenuProducts,
  },
  methods: {
    goLibraryPage() {
      window.open("http://www.leadex-furniture.com/library/#/", "_blank");
    },
    goServicePage() {
      this.$router.push({
        name: "About",
      });
    },
    toggleMenus() {
      this.$store.commit("toggleMegaMenu");
    },
    videoStartStop() {
      this.$refs.video.play();
      this.videoStatusIcon = "icon-pause";
    },

    clickMenu(path) {
      if (path === "product") return true;
      if ("/" + path == this.$route.path) return false;
      this.$router.push({ path: "/" + path });
      return false;
    },
  },
  data() {
    return {
      videoStatus: "pause",
      videoStatusIcon: "icon-playing",
      controls: false,

      activeMenu: "product",
    };
  },

  mounted() {
    // 监听视频播放
    if (this.$refs.video) {
      this.$refs.video.addEventListener("play", () => {
        this.videoStatus = "playing";
        this.controls = true;
      });
      // 监听视频暂停
      this.$refs.video.addEventListener("pause", () => {
        this.videoStatus = "pause";
        this.controls = false;
      });
    }
  },
  computed: {
    ...mapState({
      megaMenu: "showMegaMenu",
    }),
    menuAssets() {
      let imgs = {
        logo: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/05/logo1.png",
        plus: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/05/icon-open.png",
        search:
          "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/05/icon-search.png",
        ali: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/05/icon-ali.png",
        views:
          "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/05/icon-views.png",
        cart: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/05/icon-cart.png",
      };
      if (this.color === "white") {
        imgs = {
          logo: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/08/logo-white.png",
          plus: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/08/plus-white.png",
          search:
            "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/08/icon-search-white.png",
          ali: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/08/icon-alibaba-white.png",
          views:
            "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/08/icon-mall-white.png",
          cart: "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/08/icon-cart-white.png",
        };
      }
      return imgs;
    },
    isType() {
      return this.video ? "video" : "image";
    },

    boxHeight() {
      return {
        height: this.height + "px",
      };
    },
    customClass() {
      return "h" + this.height;
    },
  },
  props: {
    color: {
      default: "black",
    },
    title: {
      default: "custom title name",
    },
    swipers: {
      default: () => [],
    },
    image: {
      default: "",
    },
    type: {
      default: "image",
    },
    height: {
      default: 970,
    },

    items: {
      default: () => {
        return [
          {
            id: 2,
            thumb: "https://via.placeholder.com/1920x970/27ae60/ffffff",
          },
          {
            id: 1,
            thumb:
              "//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/06/%E9%A6%96%E9%A1%B5%E5%A4%A7%E5%9B%BE-agl.png",
          },
          {
            id: 3,
            thumb: "https://via.placeholder.com/1920x970/ae2760/ffffff",
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.h1160 {
  height: 1160px !important;
}
.h1180 {
  height: 1180px !important;
}
.h1080 {
  height: 1080px !important;
}
.h970 {
  height: 970px !important;
}
.h750 {
  height: 750px !important;
}

.image {
  position: relative;

  .content-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header {
  position: relative;
  // background-color: yellow;
  // padding-bottom: 50px;
}

.mega-menus {
  margin: 0 115px;
}

::v-deep .el-tabs__item {
  flex: 1 !important;
  text-align: center;
  height: 60px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;

  &.is-active {
    color: #409eff;
  }
}

::v-deep .el-tabs__nav {
  display: flex !important;
  float: none;
}

.mega-box {
  position: absolute;
  z-index: 997;
  width: 100%;
  left: 0;
  top: 100px;
  background-color: #fff;
  padding: 45px;
  box-sizing: border-box;

  .arrow {
    position: absolute;
    left: 0;
    top: 0;
    // height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    // background-color: red;

    .inner {
      margin-top: -25px;
      width: 45px;
      height: 45px;
      // background-color: yellow;
      background-image: url(//mwcdn.zhuoyu.co/wp-content/uploads/sites/77/2022/06/Polygon1.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.video {
  position: relative;

  &-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 98;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-btn {
    cursor: pointer;
    width: 222px;
    height: 222px;
  }
}

.back-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  height: 250px;
  z-index: 995;
}

.font-box {
  height: 20px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 996;

  // background-color: rgba($color: #000000, $alpha: 0.5);
}

.main-menu {
  margin-top: 30px;
}

.menus {
  display: flex;
  justify-content: flex-end;
  // margin-right: 160px;
}

.toggle {
  text-align: center;

  &-img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }
}

.logo-box {
  margin-left: 160px;

  .logo-img {
    width: 125px;
    height: 40px;
  }
}

.menu-box {
  margin-right: 160px;
}

.menu {
  width: 33px;
  height: 33px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 33px;
  cursor: pointer;
}
</style>
