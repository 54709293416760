<template>
  <div class="section catalogue">
    <el-row type="flex" class="grids" :gutter="30">
      <el-col class="item" :span="6" v-for="(item, idx) in items" :key="idx">
        <div class="item-cover">
          <el-image
            @click="downloadFile(item)"
            class="cover"
            :lazy="true"
            :src="item.cover"
          ></el-image>
        </div>
        <div class="item-title">
          {{ item.title }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "CatalogList",
  props: {
    items: {
      default: () => [],
    },
  },
  methods: {
    downloadFile(item) {
      window.open(item.file);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.grids {
  flex-wrap: wrap;

  .item {
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;

    &-title {
      text-align: center;
      margin-top: 35px;

      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #3f3f3f;
    }
    .cover {
      cursor: pointer;
    }
  }
}
</style>
