<template>
  <div class="container module">
    <page-header type="image" :height="750" :image="banner">
      <template v-slot:searchbox>
        <div class="search-form">
          <div class="title">Video</div>
          <el-input style="width: 350px" placeholder="Keywords"></el-input>
        </div>
      </template>
    </page-header>

    <div class="navi">
      <page-navbar class="inn" />
    </div>

    <filters @on-search="onFilter" :data="filterData" :total="total"> </filters>

    <video-list :items="items" />

    <div class="section">
      <div class="loadmore" v-if="hasmore">
        <div class="btn" @click="loadMore">Load More</div>
      </div>
    </div>

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.module {
  background-color: #fff;
}

.navi {
  position: relative;
  display: flex;
  justify-content: center;

  .inn {
    margin-top: -75px;
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/FiltersV2.vue";
import VideoList from "../components/VideoList.vue";
import { mapGetters } from "vuex";
import Api from "../utils/api";

export default {
  name: "Video",
  components: {
    PageFooter,
    PageHeader,
    PageNavbar,
    Filters,
    VideoList,
  },
  methods: {
    onFilter(args) {
      this.scene_cat_id = args["selected_col1"];
      this.series_cat_id = args["selected_col2"];
      this.video_type_id = args["selected_col3"];

      let options = {
        pg: 1,
        scene_cat_id: args["selected_col1"],
        series_cat_id: args["selected_col2"],
        video_type_id: args["selected_col3"],
      };
      this.queryItems(options, true);
    },

    loadMore() {
      if (this.hasmore) {
        let options = {
          pg: this.pg,
          scene_cat_id: this.scene_cat_id,
          video_type_id: this.video_type_id,
          series_cat_id: this.series_cat_id,
        };
        this.queryItems(options);
      }
    },

    queryItems(args, replace = false) {
      Api.getVideoList(args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }
        this.total = res.total;
        this.hasmore = res.hasmore;
        if (res.hasmore) {
          this.pg = parseInt(res.pg) + 1;
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      getProductCats: "getProductCats",
      video_types: "getVideoTypes",
      module_types: "getModuleTypes",
      getPageData: "getPageData",
      getVideoTypes: "getVideoTypes",
      getBrands: "getBrands",
    }),

    filterData() {
      return {
        type: "group",
        items: [
          {
            name: "Product Category",
            items: this.getProductCats,
            column: "two",
          },
          {
            name: "Brand",
            column: "two",
            items: this.getBrands,
          },
          {
            name: "Type",
            column: "one",
            items: this.getVideoTypes,
          },
        ],
      };
    },

    banner() {
      let key = "videos";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  created() {
    this.queryItems({ pg: 1 });
  },

  data() {
    return {
      showFilter: false,
      brand: "",
      hasmore: false,
      total: 0,
      items: [],
      scene_cat_id: 0,
      series_cat_id: 0,
      video_type_id: 0,
    };
  },
};
</script>
