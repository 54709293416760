import axios from "axios";

axios.interceptors.request.use(
    (config) => {
        let rootUrl = process.env.NODE_ENV == 'production' ? '//leadexv2.weappdev.cn/wp-json/mw/' : '//leadexv2.weappdev.cn/wp-json/mw/'
        config["url"] = rootUrl + config['url']
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const Api = {
    getHome: (params) => axios.get('home', { params }).then(res => res.data.data),
    getMenus: (params) => axios.get('menus', { params }).then(res => res.data.data),

    getAlbumList: (params) => axios.get('album', { params }).then(res => res.data),
    getVideoList: (params) => axios.get('video', { params }).then(res => res.data),
    getModuleList: (params) => axios.get('module', { params }).then(res => res.data),
    getCatalogList: (params) => axios.get('catalog', { params }).then(res => res.data),


    getCustomerService: () => axios.get('spa/3622').then(res => res.data),

    getProjectPage: () => axios.get('spa/3621').then(res => res.data),


    getSeriesMain: () => axios.get('series').then(res => res.data),
    getSeries: (id) => axios.get('series/' + id).then(res => res.data),

    getSeriesModelById: (id, params) => axios.get('series/' + id + '/model', { params }).then(res => res.data),
    getSeriesAlbumById: (id, params) => axios.get('series/' + id + '/album', { params }).then(res => res.data),
    getSeriesVideoById: (id, params) => axios.get('series/' + id + '/video', { params }).then(res => res.data),
    getSeriesCatalogById: (id, params) => axios.get('series/' + id + '/catalog', { params }).then(res => res.data),

    getProductBySceneAndSeries: (id, params) => axios.get('series/' + id + '/products', { params }).then(res => res.data),

    getProductDetails: (id) => axios.get('products/' + id).then(res => res.data),

    getSceneList: (id, params) => axios.get('scene/' + id, { params }).then(res => res.data),

    getMaterial: () => axios.get('colour').then(res => res.data),

    getNewsDetail: (id) => axios.get('news/' + id).then(res => res.data),

    getIdeaDetail: (id) => axios.get('idea/' + id).then(res => res.data),
    getIdeaList: (params) => axios.get('idea', { params }).then(res => res.data),

    getProjectDetail: (id) => axios.get('projects/' + id).then(res => res.data),
    getProjectList: (params) => axios.get('projects', { params }).then(res => res.data),

    getWoods: (params) => axios.get('material/woods', { params }).then(res => res.data),
    getFabric: (params) => axios.get('material/fabric', { params }).then(res => res.data),
    getMetal: (params) => axios.get('material/metal', { params }).then(res => res.data),

}

export default Api;
