<template>
    <div class="module">
        <ModuleList :items="items"></ModuleList>
    </div>
</template>
<script>
import ModuleList from '../ModuleList.vue'
export default {
    name: 'Module',
    components: {
        ModuleList
    },
    data() {
        return {
            items: [
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-BCH-CML-D4-CAI-20210119.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-WS4-FB.dwg",
                    id: "4821",
                    title: "Bench",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-BCH-CML-S2-CAI-20210119.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-WS2-LB.dwg",
                    id: "4823",
                    title: "Bench",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-FA-CAI-20210119.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-FD01-A.dwg",
                    id: "4825",
                    title: "Freestanding",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-FD-CAI-20210119.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-FD01-D.dwg",
                    id: "4827",
                    title: "Freestanding",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGA-CAI-20210119.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGR-A.dwg",
                    id: "4829",
                    title: "Manager",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGE-CAI-20210119.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGR-E.dwg",
                    id: "4831",
                    title: "Manager",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGF-CAI-20210122.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGR-F.dwg",
                    id: "4834",
                    title: "Manager",
                },
                {
                    cover: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGK-CAI-20210122.jpg",
                    file: "//cdn.weappdev.cn/wp-content/uploads/sites/61/2021/03/STD-MGR-L.dwg",
                    id: "4836",
                    title: "Manager",
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
</style>