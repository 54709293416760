<template>
  <div class="section blocks">
    <el-row class="items" :gutter="70">
      <el-col :span="12" v-for="item in items" :key="item.title">
        <div class="item">
          <div class="item-thumb">
            <el-image :src="item.thumb">
              <div slot="placeholder" class="image-slot">
                Loading<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div class="item-name">{{ item.title }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ComBlocks",
  props: {
    items: {
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.blocks {
  .items {
    .item {
      height: 310px;
      background-color: #f8f8f8;
      margin-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-thumb {
        width: 80px;
        height: 80px;
      }

      &-name {
        margin-top: 40px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
      }
    }
  }
}
</style>
