<template>
  <div class="module-container">
    <div class="filter-box">
      <filters @on-search="onFilter" :data="filterData" :total="total">
      </filters>
    </div>
    <div class="list-wrap">
      <CatalogueList :items="items" />
    </div>
  </div>
</template>

<script>
import Api from "../utils/api";

import CatalogueList from "../components/CatalogueList.vue";
import Filters from "../components/FiltersV2.vue";
import { mapGetters } from "vuex";
export default {
  name: "SeriesVideo",
  components: {
    CatalogueList,
    Filters,
  },
  data() {
    return {
      items: [],
      pg: 1,
      total: 0,
      hasmore: true,
    };
  },
  computed: {
    ...mapGetters(["getCatalogTypes"]),
    filterData() {
      return {
        type: "simple",
        items: this.getCatalogTypes,
      };
    },
  },
  methods: {
    onFilter(cat_id) {
      let options = {
        pg: 1,
        filter: 1,
        replace: true,
        catalog_type_id: cat_id,
      };
      this.queryItems(options);
    },

    queryItems(args) {
      let { id } = this.$route.params;
      Api.getSeriesCatalogById(id, args).then((res) => {
        if (args["replace"]) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }

        this.total = res.total;
        if (res.hasmore) this.pg += 1;
      });
    },

    loadMore() {
      if (this.hasmore) {
        this.queryItems({ pg: this.pg });
      }
    },
  },
  created() {
    this.queryItems({ pg: 1 });
  },
};
</script>

<style lang="scss" scoped>
.list-wrap {
  // padding-top: 100px;
  margin: 0 210px;
}
</style>
