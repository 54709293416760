<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "mainApp",
  mounted() {
    this.$store.dispatch("initApp");
  },
};
</script>
<style lang="scss">
@import url("https://fonts.font.im/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
.el-popover {
  border: 1px solid #000 !important;
  margin-left: 10px;
}

.VueCarousel-navigation-button:focus {
  outline: unset !important;
}

.navi {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 996;

  .inn {
    margin-top: -75px;
  }
}

.search-form {
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    margin-bottom: 31px;
  }
}

body {
  background-color: #f8f8f8;
}

* {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  font-family: "Roboto" !important;
}

.el-carousel__button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  background-color: #13b0e1 !important;
}

#app {
  // background-color: #fff;
  max-width: 1920px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.loadmore {
  padding-bottom: 105px;
  display: flex;
  justify-content: center;

  .btn {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 317px;
    height: 63px;
    background: #282828;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 38.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    letter-spacing: 0.75px;
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }
  }
}

.box-p-200 {
  margin-bottom: 200px;
}
.box-p-100 {
  margin-bottom: 100px;
}
</style>
