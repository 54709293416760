<template>
  <div class="container home">
    <page-header :height="1180" type="swiper" :swipers="swipers" />

    <!-- /section -->
    <div class="blockbox">
      <ComBlocks :items="blocks" />
    </div>

    <!-- /section -->
    <div class="section news">
      <div class="innerbox">
        <news :items="news" @goto="goto" />
      </div>
    </div>
    <!-- /section -->

    <div class="section section-idea">
      <home-idea :items="idea"></home-idea>
    </div>

    <!-- /section -->
    <!-- banner -->
    <div class="section section-brands">
      <brands :items="brands"></brands>
    </div>

    <!-- /section -->
    <!-- <home-shop :url="shop_url"></home-shop> -->
    <!-- /section -->

    <page-footer />
    <!-- /section -->
  </div>
</template>

<style lang="scss" scoped>
.box {
  padding-top: 254px;
  padding-bottom: 194px;
  background-color: #fff;
}

.news {
  background-color: #fff;
  // background-color: red;
  padding: 10px 160px;

  // padding-bottom: 400px;
}

.section-brands {
  padding-top: 275px;
  padding-bottom: 275px;
  background-color: #fff;
}
.section-idea {
  background-color: #fff;
  padding-top: 260px;
}

.blockbox {
  padding-left: 415px;
  padding-right: 415px;
  padding-top: 250px;
  // padding-bottom: 170px;
  background-color: #fff;
}
</style>
<script>
// @ is an alias to /src

import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import HomeShop from "../components/HomeShop.vue";
import HomeIdea from "../components/HomeIdea.vue";
import News from "../components/News.vue";
import ComBlocks from "../components/ComBlocks.vue";
import Brands from "../components/Brands.vue";
import Api from "../utils/api";

export default {
  name: "Home",
  components: {
    PageHeader,
    PageFooter,
    Brands,
    ComBlocks,
    News,
    HomeShop,
    HomeIdea,
  },
  methods: {
    goto(id) {
      let items = this.news;
      items.map((item, idx) => {
        idx == id ? (item["isActive"] = true) : (item["isActive"] = false);
        return item;
      });
      this.news = items;
    },
    getHome() {
      Api.getHome().then((res) => {
        console.log("home", res);
        this.blocks = res.blocks;
        this.swipers = res.swipers;
        this.news = res.news;
        this.idea = res.idea;
        this.brands = res.brands;
        this.shop_url = res.shop_url;
      });
    },
  },
  created() {
    this.getHome();
  },
  data() {
    return {
      shop_url: "",
      blocks: [],
      swipers: [],
      brands: [],
      news: [],
      idea: [],
    };
  },
};
</script>
