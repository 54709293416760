<template>
  <div class="idea">
    <el-row type="flex" justify="space-between">
      <el-col class="left">
        <div class="name">Office Idea</div>
        <div class="items">
          <div
            :class="[index == idx ? 'item active' : 'item']"
            @click="goItem(index)"
            v-for="(item, index) in items"
            :key="item.id"
          >
            {{ item.title }}
          </div>
        </div>

        <div class="desc-box">
          <div class="box" v-html="desc"></div>
          <div class="btns">
            <a @click="goIdea" class="button">View More</a>
          </div>
        </div>
      </el-col>
      <el-col class="right">
        <img :src="thumb" />
      </el-col>
    </el-row>
  </div>
  <!-- /section -->
</template>

<script>
export default {
  name: "Idea",
  methods: {
    goItem(index) {
      this.idx = index;
    },
    goIdea() {
      let id = this.idea_id;
      this.$router.push({ path: `/idea/${id}` });
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    desc() {
      let idx = this.idx;
      let item = this.items[idx];
      if (!item) return "";
      return item["desc"];
    },

    idea_id() {
      let idx = this.idx;
      let item = this.items[idx];
      if (!item) return false;
      return item["id"];
    },

    thumb() {
      let idx = this.idx;
      let item = this.items[idx];
      if (!item) return "";
      return item["thumb"];
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.button {
  color: #fff;
  padding: 0.8125rem 1.5625rem;
  text-decoration: none;
  background-color: #13b0e1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba($color: #13b0e1, $alpha: 0.8);
  }
}
.btns {
  margin-top: 30px;
}

.idea {
  // margin-top: 260px;
  background-color: #f4f2ed;

  .desc-box {
    margin-left: 160px;
    margin-top: 150px;
    margin-right: 50px;
    font-family: "Roboto";
  }

  .left {
    width: 672px;

    .name {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      display: flex;
      color: #3f3f3f;
      margin-left: 160px;
      margin-top: 136px;
    }

    .items {
      margin-top: 50px;
      margin-left: 160px;

      .item {
        position: relative;
        margin-bottom: 40px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &.active {
          color: #13b0e1;
          &:before {
            position: absolute;
            content: " ";
            width: 30px;
            height: 30px;
            background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/icon-arrow.png);
            background-repeat: no-repeat;
            background-size: 28px;
            left: -30px;
          }
        }
        &:hover {
          color: #13b0e1;
        }
      }
    }
  }

  .right {
    width: 1247px;

    img {
      width: 100%;
    }
  }
}
</style>
