import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import Api from '../utils/api'

export default new Vuex.Store({

  state: {
    showMegaMenu: false,
    showFilter: true,
    SeriesDetailItems: [],
    config: {
      scenes: [],
      series: [],
      product_cats: [],
      fabric_cats: [],
      type_cats: [],
      album_cats: [],
      module_types: [],
      video_types: [],
      catalog_types: [],
      page: {},
      settings: {},
      meta: '',
      youtube: '',
      ins: '',
      tiktok: ''
    },
    contacts: [],
    // ---------woods ---------------
    mainItems: {},
    auxiliaryItems: {},
    // ---------woods ---------------
    // ---------Modules ---------------
    ModuleItems: [],
    // ---------Modules ---------------
  },
  getters: {
    getSeriesDetailItems: state => state.SeriesDetailItems,
    getSettings: (state) => state.settings,
    getScenes: (state) => state.config['scenes'],
    getSeries: (state) => state.config['series'],
    getPageData: (state) => state.config['page'],
    getPageBanner: (state) => (pos) => state.config['page'][pos],
    getTypeCats: (state) => state.config['type_cats'],
    getCatalogTypes: (state) => state.config['catalog_types'],
    getVideoTypes: (state) => state.config['video_types'],
    getModuleTypes: (state) => state.config['module_types'],
    getFabricCats: (state) => state.config['fabric_cats'],
    getProductCats: (state) => state.config['product_cats'],
    albumCats: (state) => state.config['album_cats'],
    getSocials: (state) => {
      return [
        { name: 'facebook', link: state.config['meta'], icon: '//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-fb.png' },
        { name: 'tiktok', link: state.config['tiktok'], icon: '//cdn.weappdev.cn/wp-content/uploads/sites/77/2023/04/icon_TikTok-square.png' },
        { name: 'instagram', link: state.config['ins'], icon: '//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-ins.png' },
        { name: 'youtube', link: state.config['youtube'], icon: '//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-youtube.png' },
      ]
    },

    // getProductCats: (state) => {
    //   let arr = state.config['product_cats'];
    //   const ll = [];
    //   let chunkSize = 6
    //   for (let i = 0; i < arr.length; i += chunkSize) {
    //     const chunk = arr.slice(i, i + chunkSize);
    //     ll.push(chunk);
    //   }
    //   return ll;
    // },

    getBrands: (state) => state.config['series'],

    // getBrands: (state) => {
    //   let arr = state.config['series'];
    //   const ll = [];
    //   let chunkSize = 5
    //   for (let i = 0; i < arr.length; i += chunkSize) {
    //     const chunk = arr.slice(i, i + chunkSize);
    //     ll.push(chunk);
    //   }
    //   return ll;
    // },

  },
  mutations: {
    toggleMegaMenu(state) {
      state.showMegaMenu = !state.showMegaMenu
    },

    hideMegaMenu(state) {
      state.showMegaMenu = false
    },
    togglePageFilter(state) {
      state.showFilter = !state.showFilter
    },
    hidePageFilter(state) {
      state.showFilter = false
    },

    setSeriesDetailItems(state, items) {
      state.SeriesDetailItems = items
    },
    setupApp(state, data) {
      state.config['scenes'] = data['scenes']
      state.config['series'] = data['series']
      state.config['product_cats'] = data['product_cats']
      state.config['type_cats'] = data['type_cats']
      state.config['fabric_cats'] = data['fabric_cats']
      state.config['album_cats'] = data['album_cats']
      state.config['video_types'] = data['video_types']
      state.config['module_types'] = data['module_types']

      state.config['catalog_types'] = data['catalog_types']


      state.config['tiktok'] = data['settings']['tiktok']
      state.config['ins'] = data['settings']['ins']
      state.config['youtube'] = data['settings']['youtube']
      state.config['meta'] = data['settings']['meta']

      state.config['page'] = data['page']
      state.settings = data['settings']
      state.contacts = [
        data['settings']['contact1'],
        data['settings']['contact2'],
        data['settings']['contact3']
      ]
    },
    // ---------woods ---------------
    // ---------modules ---------------
    setupModuleItems(state, data) {
      state.ModuleItems = data.items
    }

  },
  actions: {
    initApp({ commit }) {
      Api.getMenus().then(data => {
        commit('setupApp', data)
      })
    },
    // ---------module ---------------
    queryModuleList({ commit }, args = { pg: 1, per_page: 20 }) {
      Api.getModuleList(args).then(data => {
        commit('setupModuleItems', data)
      })
    },
    // ---------module ---------------
    // ---------woods ---------------
    queryWoods({ commit }, args) {
      Api.getWoods(args).then(res => {
        console.log('aaaa', res);
      })
    }
    // ---------woods ---------------

  },

})
