<template>
  <div class="about container">
    <PageHeader :height="1080" type="image" v-if="banner" :image="banner" />

    <!--  -->
    <div class="filter-box">
      <filters @on-search="onFilter" :data="filterData" :total="total">
      </filters>
    </div>
    <!--  -->
    <div class="section">
      <el-row class="top" :gutter="100" align="bottom">
        <el-col :span="12">
          <div class="left" v-if="main">
            <el-image
              class="big"
              :preview-src-list="[main.full]"
              :src="main.thumb"
            ></el-image>
            <div class="item-title">{{ main.title }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="100" class="gridbox">
            <el-col
              :span="12"
              v-for="item in items1"
              :key="'c1' + item.id"
              class="item"
            >
              <div class="item-thumb">
                <el-image
                  class="item-thumb__url"
                  :preview-src-list="[item.full]"
                  :src="item.thumb"
                ></el-image>
              </div>
              <div class="item-title">{{ item.title }}</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!--  -->

    <el-row class="section items list" :gutter="100">
      <el-col
        :span="6"
        class="item"
        v-for="(item, idx) in items2"
        :key="'c2' + item.id"
      >
        <div class="item-thumb">
          <el-image
            class="item-thumb__url"
            :preview-src-list="[item.full]"
            :src="item.thumb"
          ></el-image>
        </div>
        <div class="item-title">{{ item.title }}</div>
      </el-col>
    </el-row>

    <div class="section loadmore" v-if="hasmore">
      <a @click="loadMore" class="btn btn-loadmore">Load More</a>
    </div>

    <page-footer />
  </div>
</template>

<script>
import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import Filters from "../components/FiltersFabric.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "Fabric",
  components: {
    PageFooter,
    PageHeader,
    Filters,
  },
  data() {
    return {
      items: [],
      total: 0,
      pg: 1,
      hasmore: false,
      banner: "",
      catid: null,
    };
  },
  watch: {
    "$route.params.id": {
      handler(catid) {
        this.catid = catid;
        this.queryItems({ pg: 1, catid }, true);
      },
      immediate: true,
      // this.queryItems({ pg: 1 });
    },
  },
  computed: {
    ...mapGetters(["getPageData", "getFabricCats"]),

    main() {
      return this.items[0];
    },
    filterData() {
      let items = this.getFabricCats.map((cat) => {
        return {
          name: cat.name,
          cat_name: cat.name,
          column: "one",
          items: cat.subs,
        };
      });
      return {
        type: "group",
        items,
      };
    },
    items1() {
      return this.items.slice(1, 5);
    },
    items2() {
      return this.items.slice(5);
    },
  },
  methods: {
    onFilter(catid) {
      this.$store.commit("hidePageFilter");
      if (catid === "all" && this.$route.path == "/fabric") return;

      let path = `/fabric/${catid}`;
      this.$router.push({
        path,
      });
    },

    loadMore() {
      if (!this.hasmore) return;
      let cat_ids = [];

      if (this.selected_col1) cat_ids.push(this.selected_col1);
      if (this.selected_col2) cat_ids.push(this.selected_col2);

      let options = {
        pg: this.pg,
        cat_ids,
      };
      this.queryItems(options);
    },

    queryItems(args, replace = false) {
      const { id } = this.$route.params;

      Api.getFabric(args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }
        this.banner = res.banner;

        this.total = res.total;
        this.hasmore = res.hasmore;
        if (res.hasmore) {
          this.pg = res.pg + 1;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.filters {
  justify-content: space-between;
  margin-top: 110px;
  margin-bottom: 130px;

  &-inner {
    width: 700px;
    padding: 45px 60px;

    .cat-name {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #3f3f3f;
      text-decoration: none;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #3f3f3f;
      }
    }

    .subcats {
      .subcat {
        margin-top: 37px;
        a {
          text-decoration: none;
          color: #3f3f3f;
          font-size: 16px;
          display: inline-block;
          padding-left: 25px;
          position: relative;

          &:before {
            content: " ";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            border: 2px solid #3f3f3f;
            position: absolute;
            left: 0;
            top: 4px;
          }

          &.active,
          &:hover {
            color: #13b0e1;
            &:before {
              border-color: #13b0e1;
            }
          }
        }
      }
    }
  }

  .nav {
    background-repeat: no-repeat;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #3f3f3f;
    padding-right: 30px;
    background-position: 100% 60%;
    cursor: pointer;
    background-size: 25px 25px;

    &.close {
      background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-filters.png);
    }
    &.open {
      background-image: url(//leadexv2.weappdev.cn/wp-content/uploads/sites/77/2022/08/icon_surprise.png);
    }
  }
}

.item-title {
  margin-top: 25px;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #3f3f3f;
}

.section {
  padding-left: 210px;
  padding-right: 210px;
}

.items {
  .item {
    width: 25%;
    margin-bottom: 50px;
    cursor: pointer;

    &-thumb__url {
      width: 100%;
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
}

.list {
  margin: 0 210px;
}

.top {
  margin: 100px 210px 70px 210px;
  display: flex;
  align-items: flex-end;
  .left {
    // margin-right: 100px;
    // background-color: green;
    .big {
      width: 100%;
    }
  }

  .gridbox {
    height: 100%;
    // background-color: red;
    align-items: flex-end;
    .item {
      margin-top: 55px;
      cursor: pointer;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-top: 0;
      }

      &-thumb__url {
        width: 100%;
      }
    }
  }
}
</style>
