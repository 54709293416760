<template>
  <div class="container module">
    <PageHeader type="swiper" :height="970" :swipers="images" />

    <div class="section section-navbar">
      <div class="navbar navs">
        <div
          @click="changeTab('overview')"
          :class="[currentTab == 'overview' ? 'nav active' : 'nav']"
        >
          <el-image class="nav-thumb" :src="getImage('overview')"></el-image>
        </div>
        <div
          @click="changeTab('spec')"
          :class="[currentTab == 'spec' ? 'nav active' : 'nav']"
        >
          <el-image class="nav-thumb" :src="getImage('spec')"></el-image>
        </div>
        <div
          @click="changeTab('images')"
          :class="[currentTab == 'images' ? 'nav active' : 'nav']"
        >
          <el-image class="nav-thumb" :src="getImage('images')"></el-image>
        </div>
        <div
          @click="changeTab('module')"
          :class="[currentTab == 'module' ? 'nav active' : 'nav']"
        >
          <el-image class="nav-thumb" :src="getImage('module')"></el-image>
        </div>
      </div>
    </div>

    <div class="section section-tabs">
      <transition name="fade">
        <div class="section-tab" v-show="currentTab == 'overview'">
          <product-overview :items="overview"></product-overview>
        </div>
      </transition>
      <transition name="fade">
        <div class="section-tab" v-show="currentTab == 'spec'">
          <product-spec
            :pnd_imgs="pnd_imgs"
            :dimension_imgs="dimension_imgs"
            :metal_ids="metal_ids"
            :fabric_ids="fabric_ids"
            :wood_ids="wood_ids"
          ></product-spec>
        </div>
      </transition>
      <transition name="fade">
        <div class="section-tab" v-show="currentTab == 'images'">
          <product-images></product-images>
        </div>
      </transition>
      <transition name="fade">
        <div class="section-tab" v-show="currentTab == 'module'">
          <product-module></product-module>
        </div>
      </transition>
    </div>

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.section {
  &-tabs {
    margin-left: 200px;
    margin-right: 200px;
  }
}

.navs {
  margin: 90px auto 95px auto;
  width: 580px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;

  .nav {
    // border-bottom: 1px solid red;
    position: relative;
    cursor: pointer;

    &:after {
      width: 0;
      height: 3px;
      content: " ";
      border-radius: 2px;
      position: absolute;
      background-color: #000;
      left: 50%;
      bottom: -52px;
      transition: all 0.3s linear;
    }

    &:hover {
      &:after {
        left: 0;
        width: 100%;
      }
    }

    &.active {
      &:after {
        left: 0;
        width: 100%;
      }

      // border-bottom: 3px solid yellow;
    }
  }
}

.module {
  background-color: #fff;
}

.navi {
  position: relative;
  display: flex;
  justify-content: center;

  .inn {
    margin-top: -75px;
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/Filters.vue";
import AlbumList from "../components/AlbumList.vue";
import ProductOverview from "../components/product/Overviews";
import ProductImages from "../components/product/Images";
import ProductSpec from "../components/product/Spec";
import ProductModule from "../components/product/Module";

import Api from "../utils/api";
export default {
  name: "Single",
  components: {
    ProductOverview,
    ProductImages,
    ProductSpec,
    ProductModule,
    PageFooter,
    PageHeader,
    PageNavbar,
    Filters,
    AlbumList,
  },
  methods: {
    queryList(args) {
      Api.getAlbumList(args).then((res) => {
        let items = this.items;
        this.items = [...items, ...res.items];
      });
    },

    changeTab(tab) {
      this.currentTab = tab;
    },
    getImage(type) {
      let file_name = type;
      if (type == this.currentTab) file_name += "-active";
      return `http://leadexv2.weappdev.cn/wp-content/uploads/sites/77/2022/08/icon-${file_name}.png`;
    },
  },

  async created() {
    const { id } = this.$route.params;
    this.queryList({ pg: 1 });
    let data = await Api.getProductDetails(id);
    console.log("data", data);
    this.images = data.images;
    this.overview = data.overview;
    this.pnd_imgs = data.pnd_imgs;
    this.dimension_imgs = data.dimension_imgs;
    this.metal_ids = data.metal_ids;
    this.fabric_ids = data.fabric_ids;
    this.wood_ids = data.wood_ids;
  },
  data() {
    return {
      currentTab: "overview",
      showFilter: false,
      images: [],
      metal_ids: [],
      fabric_ids: [],
      wood_ids: [],
      overview: [],
      dimension_imgs: [],
      pnd_imgs: [],
      items: [],
    };
  },
};
</script>
