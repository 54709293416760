<template>
  <div>
    <div class="filter-box">
      <filters @on-search="onFilter" :data="filterData" :total="total">
      </filters>
    </div>

    <div class="section-module">
      <ModuleList :items="items" />
    </div>

    <div class="section">
      <div class="loadmore" v-if="hasmore">
        <div class="btn" @click="loadMore">Load More</div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
import Api from "../utils/api";
import Filters from "../components/FiltersModules.vue";
import ModuleList from "../components/ModuleList.vue";
import { mapGetters } from "vuex";

export default {
  name: "SeriesModules",
  components: {
    Filters,
    ModuleList,
  },
  data() {
    return {
      items: [],
      pg: 1,
      total: 0,
      hasmore: true,
    };
  },
  computed: {
    ...mapGetters(["getProductCats", "getModuleTypes"]),
    filterData() {
      return {
        type: "group",
        items: [
          {
            name: "Product Category",
            cat_name: "scene_cat",
            column: "three",
            items: this.getProductCats,
          },

          {
            name: "File Type",
            cat_name: "module_type",
            column: "one",
            items: this.getModuleTypes,
          },
        ],
      };
    },
  },
  methods: {
    onFilter(id) {
      let options = {
        pg: 1,
      };

      if (id !== "all") {
        options["scene_cat_id"] = id;
      }

      this.queryItems(options, true);
    },
    queryItems(args, replace = false) {
      let { id } = this.$route.params;
      Api.getSeriesModelById(id, args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }

        this.total = res.total;
        this.hasmore = res.hasmore;
        if (res.hasmore) this.pg = 1 + res.pg;
      });
    },
    loadMore() {
      if (this.hasmore) {
        this.queryItems({ pg: this.pg });
      }
    },
  },

  watch: {
    "$route.params.id": function (id) {
      this.pg = 1;
      this.queryItems(id, { pg: this.pg });
    },
  },
  created() {
    this.queryItems({ pg: this.pg });
  },
};
</script>

<style lang="scss" scoped>
.section-module {
  // padding-top: 100px;
  margin-left: 210px;
  margin-right: 210px;
}
</style>
