<template>
  <div class="container module">
    <page-header
      v-if="banner"
      type="image"
      :height="1160"
      color="black"
      :image="banner"
    />
    <div class="items">
      <el-row
        type="flex"
        v-for="(item, idx) in items"
        :key="'x' + idx"
        :class="[idx % 2 == 1 ? 'item odd' : 'item even']"
      >
        <el-col class="left" :span="8">
          <div class="meta" @click="goDetailPage(item)">
            <div class="meta-title">{{ item.title }}</div>
            <div class="meta-desc">{{ item.desc }}</div>
          </div>
        </el-col>

        <el-col class="right" :span="16">
          <el-image
            @click="goDetailPage(item)"
            class="item-img"
            :src="item.bigimg"
          ></el-image>
        </el-col>
      </el-row>
    </div>

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.items {
  margin: 100px 270px 200px 270px;

  .item {
    margin-bottom: 80px;
    cursor: pointer;
    &-img {
      width: 100%;
    }

    &.odd {
      flex-direction: row-reverse;
      .left {
        margin-right: 0;
        margin-left: 40px;
      }
    }
  }
  .left {
    border: 1px solid #000;
    display: flex;
    margin-right: 40px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.meta {
  padding: 10px;
  &-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    color: #3f3f3f;
    margin-bottom: 20px;
  }

  &-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #3f3f3f;
  }
}
</style>

<script>
import PageHeader from "../components/PageHeaderV2.vue";
import PageFooter from "../components/PageFooter.vue";
import ComHeader from "../components/ComHeader.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/Filters.vue";
import AlbumList from "../components/AlbumList.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "OfficeIdea",
  components: {
    PageFooter,
    ComHeader,
    PageNavbar,
    Filters,
    AlbumList,
    PageHeader,
  },
  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "project";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },
  methods: {
    goDetailPage(item) {
      this.$router.push({
        path: `/projects/${item.id}`,
      });
    },
  },
  created() {
    let args = {
      per_page: 50,
    };
    Api.getProjectList(args).then((res) => {
      this.items = res.items;
    });
  },
  data() {
    return {
      showFilter: false,
      brand: "",
      items: [],
    };
  },
};
</script>
