<template>
  <div class="about container">
    <page-header
      v-if="banner"
      color="white"
      type="image"
      :image="banner"
      :height="1080"
    >
    </page-header>

    <div class="box">
      <div class="items">
        <div
          class="item"
          @click="goItem(item)"
          v-for="item in items"
          :key="item.id"
        >
          <div class="item-thumb">
            <a v-if="item.tel" :href="'tel://' + item.tel">
              <el-image class="item-img" :lazy="true" :src="item.thumb">
              </el-image>
            </a>
            <a v-else-if="item.email" :href="'mailto://' + item.email">
              <el-image class="item-img" :lazy="true" :src="item.thumb">
              </el-image>
            </a>

            <el-image v-else class="item-img" :lazy="true" :src="item.thumb">
            </el-image>
          </div>
          <div class="item-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<style lang="scss" scoped>
.box {
  padding: 210px;
  background-color: #fff;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    width: 510px;
    height: 340px;
    cursor: pointer;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    transition: all 0.3s ease-in-out;
    &:nth-of-type(2n) {
      margin-left: 70px;
    }

    &:hover {
      background: rgba($color: #ddd, $alpha: 0.5);
    }

    &-thumb {
      margin-bottom: 32px;
    }

    &-img {
      width: 80px;
    }

    &-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;

      color: #3f3f3f;
    }
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import ComBlocks from "../components/ComBlocks.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",
  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "about";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  methods: {
    goItem(item) {
      if (!!item.path) {
        this.$router.push({ path: item.path });
      }
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i2.png",
          title: "About Us",
          path: "/about-us",
        },

        {
          id: 3,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i3.png",
          title: "Call Us",
          tel: "008613535896945",
        },
        {
          id: 4,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i4.png",
          title: "E-mail Us",
          email: "info@leadexfurniture.com",
        },
        {
          id: 5,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i5.png",
          title: "News",
          path: "/news",
        },
      ],
    };
  },
  components: {
    PageFooter,
    ComBlocks,
    PageHeader,
  },
};
</script>
