<template>
  <div class="album-wrap">
    <div class="filter-box">
      <filters
        @on-search="onFilter"
        @resetItems="onResetItem"
        :data="filterData"
        :total="total"
      >
      </filters>
    </div>
    <div class="box-p-100">
      <AlbumList :items="items" />
    </div>
    <div class="section">
      <div class="loadmore" v-if="hasmore">
        <div class="btn" @click="loadMore">Load More</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../utils/api";
import Filters from "../components/FiltersV2.vue";
import AlbumList from "../components/AlbumList.vue";
import { mapGetters } from "vuex";
export default {
  name: "SeriesModel",
  components: {
    AlbumList,
    Filters,
  },
  data() {
    return {
      items: [],
      pg: 1,
      total: 0,
      hasmore: true,
    };
  },
  computed: {
    ...mapGetters(["albumCats", "getProductCats", "getTypeCats"]),
    filterData() {
      let brands = this.getProductCats;
      return {
        type: "group",
        scene_cat_id: null,
        type_cat_id: null,
        items: [
          {
            name: "Product Category",
            cat_name: "scene_cat",
            column: "three",
            items: this.albumCats,
          },

          {
            name: "File Type",
            cat_name: "type_cat",
            column: "one",
            items: this.getTypeCats,
          },
        ],
      };
    },
  },
  methods: {
    onResetItem() {
      let options = {
        pg: 1,
        // scene_cat_id: args["selected_col1"],
      };
      this.scene_cat_id = null;
      this.type_cat_id = null;
      this.queryItems(options, true);
    },

    onFilter(args) {
      let options = {
        pg: 1,
        scene_cat_id: args["selected_col1"],
        type_cat_id: args["selected_col2"],
      };
      this.scene_cat_id = args["selected_col1"];
      this.type_cat_id = args["selected_col2"];

      this.queryItems(options, true);
    },
    queryItems(args, replace = false) {
      let { id } = this.$route.params;
      Api.getSeriesAlbumById(id, args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }
        this.total = res.total;
        this.hasmore = res.hasmore;
        if (res.hasmore) res.pg += 1;
      });
    },
    loadMore() {
      if (this.hasmore) {
        let options = { pg: this.pg };

        if (this.scene_cat_id) {
          options["scene_cat_id"] = this.scene_cat_id;
          options["type_cat_id"] = this.type_cat_id;
        }

        this.queryItems();
      }
    },
  },
  created() {
    this.queryItems({ pg: 1 });
  },
};
</script>
