<template>
  <div class="section brands">
    <div class="nav">Our brands</div>

    <div class="slidebox">
      <carousel
        :per-page="4"
        :centerMode="true"
        :paginationEnabled="false"
        :navigationEnabled="true"
      >
        <slide v-for="item in items" :key="item.title">
          <div class="item" @click="goURL(item)">
            <div class="item-thumb">
              <img :src="item['thumb']" />
            </div>
            <div class="item-name">{{ item.title }}</div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.brands {
  .nav {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    text-align: center;
    color: #3f3f3f;
    margin-bottom: 46px;
  }

  .slidebox {
    margin-left: 145px;
    margin-right: 145px;
  }
  .item {
    text-align: center;
    transition: all 0.2s ease-in-out;
    margin-top: 10px;
    cursor: pointer;
    width: 350px;
    // height: 60px;
    &:hover {
      transform: scale(1.005);

      .item-name {
        color: #13b0e1;
      }
    }

    &-name {
      margin-top: 20px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
      color: #3f3f3f;
    }

    &-thumb {
      img {
        border: 1px solid #000000;
        opacity: 0.84;
        border-radius: 10px;
        max-width: 100%;
      }
    }
  }
}
</style>
<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "Brand",
  components: {
    Carousel,
    Slide,
  },
  props: {
    items: {
      default: [],
    },
  },
  methods: {
    goURL(item) {
      console.log(item);
      this.$router.push({
        name: "Series",
        params: {
          id: item.id,
        },
      });
    },
  },
  data() {
    return {};
  },
};
</script>
