<template>
  <div class="loadmore">
    <div class="btn">Load More</div>
  </div>
</template>

<script>
export default {
  name: "LoadMore",
};
</script>

<style scoped lang="scss"></style>
