<template>
  <div class="container series">
    <PageHeader :height="1160" type="image" :image="banner" />

    <div class="section section-series">
      <div class="left">
        <div class="box">
          <div class="title">Series</div>
          <div class="desc">
            We have 7 series of tables, elegant, simple, natural... With
            different scene styles, find the right products for you.
          </div>
        </div>
      </div>

      <div class="right">
        <div class="items">
          <carousel
            :per-page="3"
            :centerMode="true"
            :paginationEnabled="false"
            :navigationEnabled="true"
            navigationNextLabel="&gt;"
            navigationPrevLabel="&lt;"
          >
            <slide v-for="item in brands" :key="item.id">
              <div class="item" @click="goBrand(item)">
                <div class="item-thumb">
                  <img :src="item['thumb']" />
                </div>
                <div class="item-name">{{ item.title }}</div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>

    <!--  -->

    <div class="section section-class">
      <div class="title">Classification</div>
      <div class="desc">
        No clue? You can also look at the product classification according to
        your type needs first.
      </div>
    </div>
    <!--  -->

    <div class="section section-products products">
      <div
        class="product"
        v-for="item in cats"
        :key="item.id"
        @click="goSerisePage(item)"
      >
        <div class="product-thumb">
          <el-image :src="item.thumb" fit="fit"></el-image>
        </div>
        <div class="product-name">
          {{ item.title }}
        </div>
      </div>
    </div>
    <!--  -->

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.section {
  //
  &-class {
    // width: 226px;
    // height: 1600px;
    margin: 0 160px;
    background: #eef1f5;
    border-radius: 15px;
    text-align: center;

    .title {
      padding-top: 60px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;

      color: #3f3f3f;
    }

    .desc {
      padding-bottom: 68px;
      margin-top: 32px;
    }
  }

  //

  &-products {
    margin: 100px 160px 0 160px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 180px;

    .product {
      width: 423px;
      background: #ffffff;
      border-radius: 15px;
      margin-top: 140px;
      margin-right: 135px;

      padding: 10px;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &-name {
        margin-top: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        // padding-bottom: 34px;
      }
    }
  }

  //

  &-series {
    margin-left: 160px;
    margin-right: 160px;
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;

    .right {
      .items {
        width: 1067px;
        margin-left: 70px;

        .item {
          width: 341px;
          height: 560px;
          background-color: #fff;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &-thumb {
            overflow: hidden;

            img {
              max-width: 100%;
            }
          }

          &-name {
            text-align: center;
            padding-bottom: 33px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            color: #3f3f3f;
          }
        }
      }
    }

    .box {
      background: #f4f2ed;
      border-radius: 15px;
      height: 560px;
      width: 460px;

      .title {
        padding-top: 90px;
        font-weight: 700;
        font-size: 50px;

        // margin-top: 90px;
        margin: 0 30px;
      }

      .desc {
        margin-top: 45px;
        margin-left: 30px;
        margin-right: 30px;
      }

      // transform: rotate(90deg);
    }
  }
}
</style>

<script>
import { Carousel, Slide } from "vue-carousel";
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/Filters.vue";
import AlbumList from "../components/AlbumList.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "SeriesList",
  components: {
    Carousel,
    Slide,
    PageFooter,
    PageHeader,
    PageNavbar,
    Filters,
    AlbumList,
  },
  methods: {
    goBrand(item) {
      console.log(item);
      this.$router.push({
        path: `/series/${item.id}`,
      });
    },

    goSerisePage(item) {
      this.$router.push({
        path: `/scene/${item.id}`,
      });
    },
  },
  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "series";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },
  created() {
    Api.getSeriesMain()
      .then((res) => {
        console.log("getSeriesMain", res);
        this.brands = res.brands;
        this.cats = res.cats;
      })
      .catch((err) => {
        console.log("getSeriesMain", err);
      });
  },
  data() {
    return {
      showFilter: false,
      brands: [],
      cats: [],
    };
  },
};
</script>
