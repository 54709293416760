<template>
  <div class="about container">
    <page-header
      v-if="banner"
      color="white"
      type="image"
      :image="banner"
      :height="1080"
    >
    </page-header>

    <div class="box">
      <div class="box-content">
        <div class="title">Latest News</div>
        <div class="items">
          <div class="item" v-for="i in 5">
            <div class="item-thumb">
              <a href="/news/1">
                <img src="https://via.placeholder.com/350x200" />
              </a>
            </div>
            <div class="item-excerpt">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
              ducimus expedita distinctio, alias obcaecati explicabo facere
              neque nesciunt atque adipisci.
              <a href="/news/1">View More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<style lang="scss" scoped>
.box {
  padding: 210px;
  background-color: #fff;
  .title {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 50px;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    display: flex;
    margin-bottom: 50px;
    width: 48%;
    background-color: #efefef;
    margin-bottom: 40px;
    // padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;

    a {
      text-decoration: underline;
      color: #000;
      &:hover {
        color: #777;
      }
    }

    .item-thumb {
      width: 200px;
      height: 200px;
      // overflow: hidden;
      img {
        width: 200px;
        height: 200px;
        vertical-align: middle;
        object-fit: cover;
      }
    }
    .item-excerpt {
      padding-left: 30px;
      font-size: 18px;
      padding: 15px;
      box-sizing: border-box;
      color: #555;
      line-height: 1.5;
    }
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import ComBlocks from "../components/ComBlocks.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",
  computed: {
    ...mapGetters(["getPageData"]),
    banner() {
      let key = "about";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },

  methods: {
    goItem(item) {
      if (!!item.path) {
        this.$router.push({ path: item.path });
      }
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i2.png",
          title: "About Us",
          path: "/about",
        },

        {
          id: 3,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i3.png",
          title: "Call Us",
          tel: "008613535896945",
        },
        {
          id: 4,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i4.png",
          title: "E-mail Us",
          email: "info@leadexfurniture.com",
        },
        {
          id: 5,
          thumb: "//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/06/i5.png",
          title: "News",
          path: "/news",
        },
      ],
    };
  },
  components: {
    PageFooter,
    ComBlocks,
    PageHeader,
  },
};
</script>
