<template>
  <div class="container module">
    <page-header type="image" :height="750" :image="banner">
      <template v-slot:searchbox>
        <div class="search-form">
          <div class="title">Catalogue</div>
          <el-input style="width: 350px" placeholder="Keywords"></el-input>
        </div>
      </template>
    </page-header>

    <div class="navi">
      <page-navbar class="inn" />
    </div>

    <filters @on-search="onFilter" :data="filterData" :total="total"> </filters>

    <div class="catalog-wrap">
      <catalogue-list :items="items" />
    </div>

    <div class="section">
      <div class="loadmore" v-if="hasmore">
        <div class="btn" @click="loadMore">Load More</div>
      </div>
    </div>

    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.catalog-wrap {
  margin-left: 210px;
  margin-right: 210px;
}
.module {
  background-color: #fff;
}

.navi {
  position: relative;
  display: flex;
  justify-content: center;

  .inn {
    margin-top: -75px;
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/FiltersV2.vue";
import CatalogueList from "../components/CatalogueList.vue";
import { mapGetters } from "vuex";
import Api from "../utils/api";
export default {
  name: "Catalog",
  components: {
    PageFooter,
    PageHeader,
    PageNavbar,
    Filters,
    CatalogueList,
  },
  computed: {
    ...mapGetters({
      getBrands: "getBrands",
      getCatalogTypes: "getCatalogTypes",
      getPageData: "getPageData",
    }),
    filterData() {
      return {
        type: "group",
        items: [
          {
            name: "Brand",
            cat: "series_cat",
            items: this.getBrands,
            column: "two",
          },
          {
            name: "File Type",
            cat: "catalog_type",
            items: this.getCatalogTypes,
          },
        ],
      };
    },
    banner() {
      let key = "catalog";
      let page = this.getPageData;
      return !!page[key] ? page[key] : false;
    },
  },
  created() {
    this.queryItems({ pg: 1 });
  },
  methods: {
    onFilter(args) {
      this.series_cat_id = args.selected_col1;
      this.catalog_type_id = args.selected_col2;

      let options = {
        pg: 1,
        series_cat_id: args.selected_col1,
        catalog_type_id: args.selected_col2,
      };

      this.queryItems(options, true);
    },

    loadMore() {
      if (this.hasmore) {
        let options = {
          pg: this.pg,
          series_cat_id: this.series_cat_id,
          catalog_type_id: this.catalog_type_id,
        };
        this.queryItems(options);
      }
    },
    queryItems(args, replace = false) {
      Api.getCatalogList(args).then((res) => {
        if (replace) {
          this.items = res.items;
        } else {
          this.items = [...this.items, ...res.items];
        }

        this.total = res.total;
        this.hasmore = res.hasmore;
        if (res.hasmore) {
          this.pg = Number(res.pg) + 1;
        }
      });
    },
  },
  data() {
    return {
      showFilter: false,
      brand: "",
      total: 0,
      pg: 1,
      hasmore: false,
      items: [],
      series_cat_id: 0,
      catalog_type_id: 0,
    };
  },
};
</script>
