<template>
  <div class="section navbar">
    <el-row type="flex" align="middle" class="navs" justify="space-around">
      <el-col class="nav">
        <div class="nav-info module" :class="getCls('module')">
          <router-link to="/module">
            <el-image :src="getImage('module')"></el-image>
            <div class="nav-title">Module</div>
          </router-link>
        </div>
      </el-col>
      <el-col class="nav">
        <div class="nav-info album" :class="getCls('image')">
          <router-link to="/image">
            <el-image :src="getImage('image')"></el-image>
            <div class="nav-title">Album</div>
          </router-link>
        </div>
      </el-col>
      <el-col class="nav">
        <div class="nav-info video" :class="getCls('video')">
          <router-link to="/video">
            <el-image :src="getImage('video')"></el-image>
            <div class="nav-title">Video</div>
          </router-link>
        </div>
      </el-col>
      <el-col class="nav">
        <div class="nav-info catalogue" :class="getCls('catalogue')">
          <router-link to="/catalogue">
            <el-image :src="getImage('catalog')"></el-image>
            <div class="nav-title">Catalogue</div>
          </router-link>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.navbar {
  width: 1500px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 5px 5px 12px -3px rgba(0, 0, 0, 0.25);
  .navs {
    height: 150px;
    .nav {
      text-align: center;

      a {
        text-decoration: none;
        color: #000;
        &.router-link-active {
          color: #13b0e1;
        }
      }
      &-title {
        margin-top: 10px;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
      }

      //       nav-catalogue.png
      // nav-video.png
      // nav-module.png
      // nav-image.png
    }
  }
}
</style>
<script>
export default {
  name: "PageNavbar",
  props: {
    total: {
      default: 0,
      type: [Number, String],
    },
  },
  methods: {
    getCls(type) {
      const types = ["module", "image", "video", "catalog"];
      let name = this.$route.name.toString().toLowerCase();
      if (types.includes(name)) {
        if (name == type) return "active";
      }
      return null;
    },
    getImage(type) {
      const types = ["module", "image", "video", "catalog"];
      let name = this.$route.name.toString().toLowerCase();
      if (types.includes(name)) {
        if (name == type) type = type + "_active";
      }
      return this.icons[type];
    },
  },
  data() {
    return {
      currentHoverIcon: null,
      icons: {
        catalog_active:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-catalog-active.png",
        catalog:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-catalog.png",
        image_active:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-image-active.png",
        image:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-image.png",
        module_active:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-module-active.png",
        module:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-module.png",
        video_active:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-video-active.png",
        video:
          "http://cdn.weappdev.cn/wp-content/uploads/sites/77/2022/08/pagenav-video.png",
      },
    };
  },
};
</script>
