<template>
  <div class="section footer">
    <el-row type="flex" align="middle" class="row">
      <el-col class="nav">
        <div>LEADEX</div>
      </el-col>
      <el-col class="right">
        <div class="items">
          <div class="item about">
            <router-link to="/about">About Us</router-link>
          </div>
          <div class="item album">
            <router-link to="/image">Album</router-link>
          </div>
          <div class="item video">
            <router-link to="/video">Video</router-link>
          </div>
          <div class="item module">
            <router-link to="/module">Module</router-link>
          </div>
          <div class="item catalogue">
            <router-link to="/catalogue">Catalogue</router-link>
          </div>
          <div class="item service">
            <router-link to="/service">Customer Service</router-link>
          </div>
        </div>
      </el-col>
    </el-row>

    <!--  -->
    <el-row type="flex" align="start" class="row contactus">
      <el-col class="nav"> CONTACT US</el-col>
      <el-col class="right">
        <el-row type="flex" class="sales-wrap" :gutter="50">
          <el-col
            class="sales"
            v-for="(user, idx) in contacts"
            :key="'sales' + idx"
            :span="8"
          >
            <div class="left"><i class="el-icon-user-solid"></i></div>
            <div class="right">
              <div class="inner" v-html="user"></div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--  -->
    <!--  -->
    <el-row type="flex" align="start" class="row">
      <el-col class="nav">
        <div>FIND US</div>
      </el-col>
      <el-col class="right findus">
        <el-row type="flex" :gutter="100">
          <el-col class="location" :span="9">
            <div class="addr addr1">
              UNIT 322, BUILDING 4, GREEN CREATIVE PARK NO.61 XIANAN ROAD,
              GUICHENG NANHAI, FOSHAN 528200 GUANGDONG CHINA
            </div>
          </el-col>
          <el-col class="location" :span="9">
            <div class="addr addr1">
              NO. 1, RITIAN ROAD, SONGXIA IND. ZONE, SONGGANG, SHISHAN TOWN,
              NANHAI DIST., FOSHAN, GUANGDONG, CHINA (MAINLAND)
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" align="middle" class="row">
      <el-col class="nav">
        <div>FOLLOW US</div>
      </el-col>
      <el-col class="right">
        <div class="socials">
          <div class="social" v-for="item in getSocials" :key="item.name">
            <a :href="item.link" target="_blank"><img :src="item.icon" /></a>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.sales {
  display: flex;
  align-items: center;
  // margin-bottom: 50px;
  .left {
    font-size: 50px;
    color: #777;
  }

  .right {
    .inner {
      white-space: pre-wrap;
      line-height: 1.9;
      font-family: "Roboto";
    }
  }

  &-info {
    margin-bottom: 10px;
  }
}

.sales-wrap {
  flex-wrap: wrap;
  margin-right: 250px;
}

.contactus {
  margin-right: 100px;
}

.footer {
  padding-top: 200px;
  padding-bottom: 200px;
  background-color: #efefef;

  a {
    color: #3f3f3f;
    text-decoration: none;

    &:hover {
      color: #13b0e1;
    }
  }

  .findus {
    display: flex;

    .location {
      background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-location.png);
      background-repeat: no-repeat;
      background-size: 35px 35px;
      padding-left: 65px;
      background-position-y: 5px;
      margin-left: 45px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #3f3f3f;
      line-height: 2;
      cursor: pointer;
      .addr2 {
        margin-top: 30px;
      }
    }

    .contacts {
      //   background-color: #13b0e1;
      margin-left: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .contact {
        background-size: 30px 30px;
        background-repeat: no-repeat;
        line-height: 30px;
        padding-left: 60px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;

        &.phone {
          background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-phone.png);
        }

        &.whatsapp {
          background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-whatsapp.png);
        }

        &.mailbox {
          background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-mailbox.png);
        }

        &.tiktok {
          background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-mailbox.png);
        }
      }
    }
  }

  .items {
    display: flex;

    .item {
      padding-left: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      margin-right: 55px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:last-child {
        margin-right: 0;
      }

      &.about {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-aboutus.png);
      }

      &.album {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-album.png);
      }

      &.video {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-video.png);
      }

      &.module {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-module.png);
      }

      &.catalogue {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-catalogue.png);
      }

      &.service {
        background-image: url(//cdn.weappdev.cn/wp-content/uploads/sites/77/2022/05/icon-service.png);
      }
    }
  }

  .row {
    margin-top: 130px;

    &:first-child {
      margin-top: 0;
    }
  }

  .right {
    margin-left: 30px;
  }

  .nav {
    margin-left: 290px;
    // background-color: red;
    width: 250px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #3f3f3f;
  }

  .socials {
    display: flex;

    .social {
      margin-right: 54px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapState(["contacts"]),
    ...mapGetters(["getSocials"]),
  },
};
</script>
