<template>
  <div class="items">
    <div
      class="item"
      v-for="(cat, idx) in items"
      :key="cat.id"
      :class="[idx % 2 == 0 ? 'even' : 'odd']"
      @click="goPage(cat)"
    >
      <div class="left">
        <div class="title">{{ cat.title }}</div>
        <div class="subtitle">{{ cat.subtitle }}</div>
        <div class="desc">
          {{ cat.desc }}
        </div>
      </div>
      <div class="right">
        <el-image class="item-img" :src="cat.thumb"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SeriesDetail",
  computed: {
    ...mapGetters({ items: "getSeriesDetailItems" }),
  },

  methods: {
    goPage(cat) {
      console.log("goPage", cat);
      let name = cat.title.toString().toLowerCase().trim();
      let id = this.$route.params.id;
      this.$router.push({ path: `/series/${id}/scene/${name}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  margin-top: 180px;
  margin-right: 200px;
  margin-left: 200px;
  margin-bottom: 200px;
  .item {
    display: flex;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    margin-bottom: 50px;
    justify-content: center;
    &.odd {
      flex-direction: row-reverse;
      .left {
        margin-left: 75px;
        margin-right: 0;
      }
    }

    &.even {
      .left {
        margin-right: 75px;
        margin-left: 0;
      }
    }

    &-img {
      width: 975px;
      height: 650px;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 75px;
      .title {
        font-size: 50px;
        line-height: 59px;
      }

      .desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #3f3f3f;
        // margin-top: 245px;
      }

      .subtitle {
        margin-top: 38px;
        font-size: 25px;
        line-height: 29px;
      }
    }

    .right {
    }
  }
}
</style>
