<template>
  <div class="container scene">
    <PageHeader v-if="banner" :height="1160" type="" :image="banner" />

    <div class="page-filter">
      <Filters
        @on-search="onFilter"
        @resetItems="onResetItem"
        :data="filterData"
        :total="total"
      >
      </Filters>
    </div>

    <el-row class="items" type="flex">
      <el-col
        class="item"
        :span="8"
        v-for="(item, idx) in products"
        :key="'x' + idx"
      >
        <div class="item-thumb" @click="goProduct(item.id)">
          <el-image
            class="item-thumb__url"
            :src="item.cover"
            mode="cover"
          ></el-image>
        </div>
        <div class="item-name">{{ item.title }}</div>
      </el-col>
    </el-row>

    <!--  -->
    <page-footer />
  </div>
</template>
<style lang="scss" scoped>
.scene {
  background-color: #fff;
}

.items {
  // padding-top: 120px;
  background-color: #fff;
  flex-wrap: wrap;
  padding-left: 160px;
  padding-right: 160px;

  .item {
    margin-bottom: 130px;
    // background-color: #ddd;
    cursor: pointer;
    text-align: center;
    &-thumb__url {
      width: 100%;
      max-width: 400px;
    }

    &-name {
      margin-top: 35px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #3f3f3f;
    }
  }
}
</style>

<script>
import PageFooter from "../components/PageFooter.vue";
import PageHeader from "../components/PageHeaderV2.vue";
import PageNavbar from "../components/PageNavbar.vue";
import Filters from "../components/FiltersOne.vue";
import AlbumList from "../components/AlbumList.vue";
import Api from "../utils/api";
import { mapGetters } from "vuex";
export default {
  name: "Module",
  components: {
    PageFooter,
    PageNavbar,
    PageHeader,
    Filters,
    AlbumList,
  },
  methods: {
    onFilter(val) {
      let options = {
        pg: 1,
      };
      if (val != "all") {
        options.serise_cat_id = val;
      }
      this.serise_cat_id = val;

      let id = this.$route.params.id;

      this.queryList(id, options, true);
    },
    onResetItem() {},

    goProduct(id) {
      this.$router.push({ params: { id }, name: "Products" });
    },

    queryList(id, args, replace = false) {
      Api.getSceneList(id, args).then((res) => {
        let products = res.products;
        this.banner = res.banner;
        this.products = products;
        this.total = res.total;
      });
    },
  },

  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.queryList(id, { pg: 1 });
    },
  },

  created() {
    let id = this.$route.params.id;
    this.queryList(id, { pg: 1 });
  },

  computed: {
    ...mapGetters(["getSeries"]),
    filterData() {
      return {
        type: "simple",
        serise_cat_id: null,
        items: [
          {
            name: "Product Category",
            cat_name: "scene_cat",
            column: "two",
            items: [{ id: "all", name: "All" }, ...this.getSeries],
          },
        ],
      };
    },
  },
  data() {
    return {
      showFilter: false,
      brand: "",
      total: 0,
      banner: null,
      serise_cat_id: null,
      items: [],
      products: [],
    };
  },
};
</script>
